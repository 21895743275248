import {categoryColors, classNames, formatDate, shortDate} from "../../../helpers";
import _ from "lodash";
import {Button} from "../../../components/catalyst/button";
import {gql, useMutation} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import {LockClosedIcon, LockOpenIcon} from "@heroicons/react/16/solid";
import {PLAN_DETAILS} from "../../../fragments";

const SET_ROUTE_LOCK = gql`
  mutation setRouteLock($routeId: ID!, $locked: Boolean!) {
    routeSetLock(input: {routeId: $routeId, locked: $locked}) {
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`

const RouteSelector = ({value, onRouteChange, onPlanChange, routes}) => {

  const [setRouteLock] = useMutation(SET_ROUTE_LOCK)

  const onToggleRouteLock = (route) => {
    setRouteLock({variables: {routeId: route.id, locked: !route.locked}})
    .then(response => {
      const thePlan = response.data.routeSetLock
      onPlanChange(thePlan)
    })
    .catch(error => {
      enqueueSnackbar(`Could not change the route's lock: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  const isCurrent = (tab) => {
    return tab === value
  }

  const tabLabel = (route) => {
    return formatDate(route.date, {
      year: undefined,
      month: 'short',
      day: '2-digit',
      weekday: "short",
      hour: undefined,
      minute: undefined
    })
  }

  return (
    <div className="sm:block ">
      <nav className="flex space-x-4 px-2 max-w-fit overflow-x-auto " aria-label="Tabs">
        {_.sortBy(routes, "date").map((route, index) => (
          <div
            key={route.id}
            className={classNames(
              isCurrent(route) ? 'bg-slate-200 text-indigo-500 border' : 'text-gray-500 hover:text-gray-700',
              'rounded-t-md px-3 py-2 text-sm font-medium flex items-center gap-x-2'
            )}
            onClick={() => onRouteChange(route)}
          >
            <div className="size-3" style={{backgroundColor: categoryColors[index]}}/>
            <span className="whitespace-nowrap">{tabLabel(route)}</span>
            <Button outline onClick={() => onToggleRouteLock(route)} className="p-0 cursor-pointer">
              { route.locked ? <LockClosedIcon /> : <LockOpenIcon /> }
            </Button>
          </div>
        ))}
      </nav>
    </div>
  )
}

export default RouteSelector