import {RulerIcon, Sailboat, TimerIcon} from "lucide-react";
import React from "react";
import {humanizeDistance, humanizeDuration} from "../../../../helpers";
import _ from "lodash";

export const Leg = ({leg}) => {

  const ferry = _.filter(leg.steps, x => x["maneuver"] === "ferry")

  const ferry_distance = _.sumBy(ferry, x => x.distance.value)
  const ferry_time = _.sumBy(ferry, x => x.duration.value)

  return (
    <div className="relative flex items-start gap-x-3">
      <div className="text-xs flex gap-x-4 ml-5 pl-8 border-l-2">
        <span className="flex gap-x-1 items-center"><RulerIcon className="size-3"/>{leg.distance.text}</span>
        <span className="flex gap-x-1 items-center"><TimerIcon className="size-3"/>{leg.duration.text}</span>
      </div>

      {!_.isEmpty(ferry) &&
        <div className="text-xs flex gap-x-2 ml-5 pl-8">
          <Sailboat className="size-4"/>
          <span className="flex gap-x-1 items-center">
            <RulerIcon className="size-3"/>{humanizeDistance(ferry_distance)}
          </span>
          <span className="flex gap-x-1 items-center">
            <TimerIcon className="size-3"/>{humanizeDuration(ferry_time)}
          </span>
        </div>
      }
    </div>
  )
}