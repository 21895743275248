import locations from "../data/optometrists.json"
import clusters from "../data/clusters.json"
import paths from "../data/paths.json"
import {
  calculateViewportCenter,
  categoryColors,
  classNames,
  clusterName,
  decodePolyline,
  sumViewports
} from "../helpers";
import {GoogleMap, Marker, Polyline, useLoadScript} from '@react-google-maps/api';
import {useState} from "react";
import {Outlet} from "react-router-dom";
import Tabs from "../components/Tabs";


const CarRoutes = ({}) => {

  const [currentCluster, setCurrentCluster] = useState(null)
  const tabs = clusters.map(c => ({ name: `${clusterName(c)}`, href: `/routes/${c.id}`}))


  return (
    <>
      <h1 className="text-2xl font-bold">Routes</h1>
      <Tabs tabs={tabs}/>
      <Outlet/>
    </>
  )
}

export default CarRoutes