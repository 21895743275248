import React from "react";
import {Field, FieldGroup, Fieldset, Label} from "../../../components/catalyst/fieldset";
import AddressSelector from "./AddressSelector";


const PickTerminus = ({startingPlace, endingPlace, onStartingChange, onEndingChange, canCreate}) => {

  return (
    <div>
      <Fieldset>
        <FieldGroup>
          <Field>
            <Label>Starting address</Label>
            <AddressSelector defaultValue={startingPlace?.formatted_address || ""}
                             onPlaceSelected={onStartingChange}
                             placeholder="address..."
                             disabled={!canCreate}
            />
          </Field>

          <Field>
            <Label>Ending address</Label>
            <AddressSelector defaultValue={endingPlace?.formatted_address || ""}
                             onPlaceSelected={onEndingChange}
                             placeholder="address..."
                             disabled={!canCreate}
            />
          </Field>
        </FieldGroup>
      </Fieldset>
    </div>
  )
}

export default PickTerminus

