import {Link} from "react-router-dom";
import {formatTime, isVisitInWorkingHours} from "../../../../helpers";
import VisitBadge from "../VisitBadge";
import VisitStateDropdown from "../VisitStateDropdown";
import React from "react";
import {AlertTriangleIcon, Move, TimerIcon, TriangleIcon} from "lucide-react";
import {default as MoveButton} from "../../visits/buttons/Move"

export const STATE_BG_COLOR = {
  "PLANNED": "bg-yellow-100",
  "CONFIRMED": "bg-blue-100",
  "COMPLETED": "bg-green-100",
  "CANCELLED": "bg-red-100",
  "AUTHORIZED": "bg-purple-100"
}

const ICON_COLOR = {
  "PLANNED": "text-yellow-700",
  "CONFIRMED": "text-blue-700",
  "COMPLETED": "text-green-700",
  "CANCELLED": "text-red-700",
  "AUTHORIZED": "text-purple-700"
}

export const Visit = ({plan, visit, index, onPlanChange, time, user, onRecenter}) => {
  const {branch} = visit
  const {place} = branch

  const label = String.fromCharCode(65 + index)
  const isInWorkTime = isVisitInWorkingHours(user, visit, time)
  const visitSpan = parseInt(visit.timeSpan.split("_")[1])

  return (
    <div className="relative flex items-center gap-x-3 bg-slate-100 py-2">
      <div className="relative px-1">
        <div
          className={`flex h-8 w-8 items-center justify-center rounded-full ${STATE_BG_COLOR[visit.state.toUpperCase()]} ring-1 ring-slate-500`}>
          <span className={`size-5 font-bold text-center mb-1 ${ICON_COLOR[visit.state.toUpperCase()]}`}>{label}</span>
        </div>
      </div>
      <div className="min-w-0 flex-1 pb-0 flex-col text-xs">
        <div className="text-gray-500">
          <Link to={`/visits/${visit.id}`} className="">
            <div className="flex flex-row gap-x-4 items-center">
              <div className="font-normal">{formatTime(time)}</div>
              <div>
                <div className="flex flex-row gap-x-1 items-center flex-1">
                  <div className="font-bold text-gray-800">
                    {branch.name}
                  </div>
                  <VisitBadge visit={visit}/>
                </div>
                <div className="flex gap-x-4">
                  {place.metadata.formatted_address}
                  <div className="flex gap-x-0.5 items-center">
                    <TimerIcon className="size-3"/>
                    {visitSpan}m
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="flex-0">
        <div className="flex gap-x-0.5 items-center">
          {!isInWorkTime && <AlertTriangleIcon className="size-4 text-red-500 stroke-2"/>}
          <MoveButton plan={plan} visit={visit} onChange={onPlanChange}/>
          <VisitStateDropdown visit={visit} onPlanChange={onPlanChange}/>
        </div>
      </div>
    </div>
  )

}