import {ArrowRightToLineIcon, LocateFixed} from "lucide-react";
import {formatTime} from "../../../../helpers";
import Autocomplete from "react-google-autocomplete";
import React, {useEffect, useRef} from "react";
import {gql, useMutation} from "@apollo/client";
import {PLAN_DETAILS} from "../../../../fragments";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import _ from "lodash";

const SET_ENDING = gql`
  mutation setRouteEnding($id:ID!, $uuid:String!) {
    routeSetEnding(input: {routeId: $id, placeUuid: $uuid}){
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`
const api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY


export const Ending = ({route, time, onPlanChange, onRecenter}) => {
  const [setEnding] = useMutation(SET_ENDING)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.value = route.ending.metadata.formatted_address
    }
  }, [route]);

  const onPlaceChange = (place) => {
    if (_.isUndefined(place.place_id)) {
      enqueueSnackbar(`Failed to geocode the address '${place.name}'. Please try simplifying it and confirm it exists.`, {variant: "error"})
      Sentry.captureMessage("Address could not be geocoded", {extra: place})
      return
    }

    setEnding({variables: {id: route.id, uuid: place.place_id}})
    .then(response => {
      const thePlan = response.data.routeSetEnding
      onPlanChange(thePlan)
    })
    .catch(error => {
      console.error(error)
      enqueueSnackbar("Could not set the ending of the route.", {variant: "error"})
      Sentry.captureException(error)
    })
  }

  return (
    <div className="relative flex items-center gap-x-3 pr-1">
      <div className="relative px-1">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-1 ring-white">
          <ArrowRightToLineIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0 items-center">
        <div className=" text-xs text-gray-500">
          <div className="font-medium text-gray-900">
            <div className="flex flex-row items-center gap-x-4">
              <div className="font-normal">{formatTime(time)}</div>
              <div className="flex-1">
                <Autocomplete
                  ref={ref}
                  apiKey={api_key}
                  onPlaceSelected={onPlaceChange}
                  options={{types: ['geocode', "establishment"], componentRestrictions: {country: "ca"}}}
                  defaultValue={route.ending.metadata.formatted_address}
                  className=" w-full rounded px-2 py-1 border"
                  placeholder="ending address..."
                />
              </div>
              {/*<div className="flex gap-x-0.5 items-center">*/}
              {/*  <Button onClick={() => onRecenter(route.ending.metadata.geometry)} color="white"  disabled={!_.isFunction(onRecenter)}>*/}
              {/*    <LocateFixed className="size-3"/>*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}