import {Button} from "../../../../components/catalyst/button";
import {ArrowRightLeftIcon} from "lucide-react";
import React, {useState} from "react";
import {TargetSelect} from "./TargetSelect";
import {gql, useMutation} from "@apollo/client";
import {PLAN_DETAILS} from "../../../../fragments";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";


const MOVE_VISIT = gql`
  mutation moveVisit($visitId:ID!, $date:ISO8601Date!) {
    visitMove(input: {visitId: $visitId, date: $date}) {
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`
const REMOVE_VISIT = gql`
  mutation removeVisit($visitId: ID!) {
    visitRemove(input: {visitId: $visitId}) {
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`
const RELEASE_VISIT = gql`
  mutation releaseVisit($visitId: ID!) {
    visitRelease(input: {visitId: $visitId}) {
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`

const Move = ({plan, visit, onChange}) => {
  const [targetOpen, setTargetOpen] = useState(false)
  const [moveVisit] = useMutation(MOVE_VISIT)
  const [removeVisit] = useMutation(REMOVE_VISIT)
  const [releaseVisit] = useMutation(RELEASE_VISIT)

  const canMove = visit.canMove.value

  const onMove = ({visitId, date}) => {
    moveVisit({variables: {visitId: visitId, date: date}})
    .then(response => {
      onChange(response.data.visitMove)
    })
    .catch(error => {
      enqueueSnackbar(`Could not move the visit. ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }
  const onRemove = ({visitId}) => {
    removeVisit({variables: {visitId: visitId}})
    .then(response => {
      onChange(response.data.visitRemove)
    })
    .catch(error => {
      enqueueSnackbar(`Could not remove the visit. ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }
  const onRelease = ({visitId}) => {
    releaseVisit({variables: {visitId: visitId}})
    .then(response => {
      onChange(response.data.visitRelease)
    })
    .catch(error => {
      enqueueSnackbar(`Could not release the visit. ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  const handleMoveConfirm = (selection) => {
    setTargetOpen(false)
    switch (selection.operation) {
      case "move":
        onMove(selection);
        break;
      case "remove":
        onRemove(selection);
        break;
      case "release":
        onRelease(selection);
        break;
      default: // do nothing
    }
  }


  return (
    <>
      <Button onClick={() => setTargetOpen(true)} outline disabled={!canMove}>
        <ArrowRightLeftIcon className="size-3"/>
      </Button>
      <TargetSelect plan={plan}
                    visit={visit}
                    isOpen={targetOpen}
                    onClose={() => setTargetOpen(false)}
                    onConfirm={handleMoveConfirm}/>
    </>
  )
}
export default Move