import BaseCard from "../../components/BaseCard";
import {formatDateRange} from "../../helpers";
import {Link} from "react-router-dom";


const mapUrl = (plan) => {
  if (_.isNil(plan.boundingBox)) {
    return null
  }

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const size = '400x300'; // Width x Height
  const {southwest, northeast} = plan.boundingBox
  const visible = `${southwest.lat},${southwest.lng}|${northeast.lat},${northeast.lng}`
  const mapType = 'roadmap'; // Can be 'roadmap', 'satellite', 'hybrid', or 'terrain'

  // return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${size}&maptype=${mapType}&key=${apiKey}`;
  return `https://maps.googleapis.com/maps/api/staticmap?size=${size}&maptype=${mapType}&visible=${visible}&key=${apiKey}`

}

const PlanCard = ({plan}) => {



  const title = formatDateRange(plan.dateRange, {
    year: 'numeric', month: 'long', day: 'numeric', hour: undefined, minute: undefined
  })


  return (
    <BaseCard title={title}>
      <Link to={`/plans/${plan.id}`}>
        <img src={mapUrl(plan)} alt="Google Map"/>
      </Link>
    </BaseCard>
  )
}

export default PlanCard