import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import React from 'react';
import {setContext} from "@apollo/client/link/context";
import {useAuth} from "./useAuth";

const AuthorizedApolloProvider = ({children}) => {
  const {jwtToken} = useAuth()

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_HOST}/graphql`,
  });

  // const errorLink = onError(({graphQLErrors, networkError}) => {
  //   if (graphQLErrors)
  //     graphQLErrors.forEach(({message, locations, path}) => {
  //       console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,)
  //     })
  // })
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${jwtToken}`
      }
    };
  });
  // const regularLink = ApolloLink.from([authLink, errorLink]);


  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(), // TODO: make cache support Relay pagination
    link: authLink.concat(httpLink),
  })

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default AuthorizedApolloProvider;
