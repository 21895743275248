import {Button} from "./catalyst/button";
import {PhoneIcon} from "@heroicons/react/24/solid";
import React from "react";
import _ from "lodash";

const PhoneButton = ({number}) => {
  const canCall = !_.isUndefined(number)

  return (
    <a href={`tel:${number}`}>
      <Button color="green" disabled={!canCall} className="cursor-pointer">
        <PhoneIcon className="size-5"/>
        Call
      </Button>
    </a>
  )
}

export default PhoneButton
