import {Dialog, DialogActions, DialogBody, DialogTitle} from "../../../components/catalyst/dialog";
import {Wizard} from "react-use-wizard";
import PickDate from "./PickDate";
import React, {useEffect, useMemo, useState} from "react";
import PickTerminus from "./PickTerminus";
import _ from "lodash";
import {WizardFooter} from "./WizardFooter";
import {gql, useMutation} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import {WizardHeader} from "./WizardHeader";
import {Button} from "../../../components/catalyst/button";

const CREATE_PLAN = gql`
  mutation createPlan($startDate: ISO8601Date!, $startPlace: String!, $endPlace: String!) {
    planCreate(input: {startDate: $startDate, startPlaceUuid: $startPlace, endPlaceUuid: $endPlace}) {
      id
    }
  }
`

const PlanCreateWizard = ({canCreate, userPlace, open, onClose, onPlanCreated, disabledDays}) => {

  const [planStartDate, setPlanStartDate] = useState(new Date())
  const [startingPlace, setStartingPlace] = useState(userPlace)
  const [endingPlace, setEndingPlace] = useState(userPlace)
  const [createPlan] = useMutation(CREATE_PLAN)

  const canCreatePlan = useMemo(() => {
    return !_.isNil(planStartDate) &&
      !_.isNil(startingPlace) && !_.isNil(endingPlace) &&
      startingPlace.hasOwnProperty("place_id") &&
      endingPlace.hasOwnProperty("place_id") &&
      canCreate
  }, [planStartDate, startingPlace, endingPlace])

  const onCreatePlan = () => {
    createPlan({
      variables: {
        startDate: planStartDate,
        startPlace: startingPlace["place_id"],
        endPlace: endingPlace["place_id"]
      }
    })
    .then(response => {
      enqueueSnackbar(`Plan created.`, {variant: "success"})
      if (_.isFunction(onPlanCreated)) onPlanCreated(response.data.planCreate.id)
    })
    .catch(error => {
      enqueueSnackbar(`Plan could not be created: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  return (
    <Dialog open={open} onClose={onClose} size={"xl"}>
      <DialogTitle>Create a Plan</DialogTitle>
      <DialogBody className="flex flex-col gap-y-5">
        <PickDate
          planStartDate={planStartDate}
          setPlanStartDate={setPlanStartDate}
          disabledDays={disabledDays}
        />
        <PickTerminus
          canCreate={canCreate}
          startingPlace={startingPlace}
          endingPlace={endingPlace}
          onStartingChange={setStartingPlace}
          onEndingChange={setEndingPlace}
        />
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>Cancel</Button>
        <Button onClick={onCreatePlan} color="green" disabled={!canCreatePlan}>
          Create Plan
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlanCreateWizard