import BaseCard from "../../../components/BaseCard";


const PhotosCard = ({}) => {

  const Photo = ({data}) => {
    const {url, filename, filesize} = data

    return (
      <li className="relative">
        <div className="group aspect-h-16 aspect-w-16 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
          <img
            src={url}
            alt=""
            className="pointer-events-none object-cover group-hover:opacity-75"
          />
          <button type="button" className="absolute inset-0 focus:outline-none">
            <span className="sr-only">View details for {filename}</span>
          </button>
        </div>
        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{filename}</p>
        <p className="pointer-events-none block text-sm font-medium text-gray-500">{filesize} MB</p>
      </li>
    )
  }

  const photos = []
    // {
    //   url: "https://lh5.googleusercontent.com/p/AF1QipPrSJb2XdeWQ2sHAPm5oxekOMJz_XfWekuN3Tb9=w203-h152-k-no",
    //   filename: "IMG_4985.HEIC",
    //   filesize: 3.9
    // }
  // ]


  return (
    <BaseCard title="Photos Gallery">
      <ul role="list"
          className="p-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {photos.map((photo, index) => <Photo data={photo} key={index}/>)}
      </ul>
    </BaseCard>
  )
}

export default PhotosCard