import {useMemo} from 'react';
import _ from "lodash"

export function useAuth() {
  const KEY = "easyallot-jwt"

  const jwtToken = useMemo(() => {
    return window.localStorage.getItem(KEY)
  }, [])

  const isAuthenticated = useMemo(() => {
    return jwtToken !== null
  }, [jwtToken])


  const parsedToken = useMemo(() => {
    if (_.isNull(jwtToken)) return null

    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  },  [jwtToken])

  const signIn = (token, callback) => {
    window.localStorage.setItem(KEY, token)
    // console.log(token)
    if (_.isFunction(callback)) callback()
  };

  const isDemo = useMemo(() => {
    return jwtToken === "DEMO"
  }, [jwtToken])

  let signOut = (callback) => {
    window.localStorage.removeItem(KEY)
    // console.table(window.localStorage)
    if (_.isFunction(callback)) callback(``)
  };

  return { jwtToken, signIn, signOut, isAuthenticated, parsedToken, isDemo };
}


