import React, {useEffect, useMemo, useState} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import {innerButtonStyle, leftButtonStyle, rightButtonStyle} from "../helpers";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {flexRender, getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {SearchBox} from "./SearchBox";

const PAGE_QUERY = gql`
  query searchBranchesPage($query: String!, $offset: Int!, $limit: Int!) {
    searchBranches(query: $query, page: { offset: $offset, limit: $limit}) {
      total
      branches {
        id
        name
        client {
          id
          name
          fullName
          settings
        }
        place {
          metadata
          name
        }
      }
    }
  }
`

const Paginator = ({table, loading, total}) => {

  return (
    <div className="flex items-center gap-2">
      <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        className={leftButtonStyle}
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronDoubleLeftIcon className="h-5 w-5"/>
      </button>
      <button
        className={innerButtonStyle}
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronLeftIcon className="h-5 w-5"/>
      </button>
      <button
        className={innerButtonStyle}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <ChevronRightIcon className="h-5 w-5"/>
      </button>
      <button
        className={rightButtonStyle}
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        <ChevronDoubleRightIcon className="h-5 w-5"/>
      </button>
      </span>

      <span className="flex items-center gap-1 text-sm">
        <div>Page</div>
        <strong>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </strong>
      </span>
      <span>total: {total}</span>

      {loading ? 'Loading...' : null}
    </div>
  )
}


const BranchesDataTable = ({query}) => {
  const defaultData = useMemo(() => [], [])
  const [{pageIndex, pageSize}, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  })
  const [tableData, setTableData] = useState({rows: [], count: 0})

  const [fetchPage, {loading, error, data}] = useLazyQuery(PAGE_QUERY, {
    fetchPolicy: "network-only",
    variables: {query: query, offset: pageIndex * pageSize, limit: pageSize},
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setTableData({rows: [], count: 0})
      } else {
        const {total, branches} = data.searchBranches
        setTableData({rows: branches, count: total})
      }
    }
  })

  useEffect(() => {
    console.log("fetch page")
    fetchPage()
  }, [query]);

  const pagination = useMemo(
    () => ({pageIndex, pageSize}),
    [pageIndex, pageSize]
  )

  const columns = [
    {
      id: "branch name",
      header: 'Branch Name',
      cell: ({row}) => {
        const branch = row.original
        const name = branch.name ?? branch.place.name
        return (
          <Link to={`/places/${branch.id}`} className="underline">
            {name}
          </Link>
        )
      }
    },
    {
      id: "client name",
      header: "Client",
      cell: ({row}) => {
        const branch = row.original
        return (
          <Link to={`/clients/${branch.client.id}`} className="underline">
            {branch.client.fullName ?? branch.client.name}
          </Link>
        )
      }
    },
    {
      id: "address",
      header: "Address",
      cell: ({row}) => {
        const branch = row.original
        return branch.place.metadata.formatted_address
      }
    }
  ]

  const table = useReactTable({
    data: tableData?.rows ?? defaultData,
    columns: columns,
    pageCount: (Math.ceil(tableData?.count / pageSize)) ?? -1,
    state: {
      pagination,
      // rowSelection
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    debugTable: true,
    enableMultiRowSelection: true,
    // onRowSelectionChange: setRowSelection,
    autoResetPageIndex: false,
    getRowId: (originalRow, index, parent) => {
      return originalRow.id
    },
  })

  return (
    <div className="">
      <div className="flow-root">

        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 min-h-[780px]">
            <table className="min-w-full divide-y divide-gray-300 mt-2 ">
              <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}
                          scope="col"
                          className={"whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"}
                      >
                        {header.isPlaceholder ? null : (
                          <div>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </div>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white text-xs">
              {table.getRowModel().rows.map(row => {
                return (
                  <tr key={row.id} className="divide-x divide-gray-200">
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <td className="whitespace-nowrap px-2 py-1 text-sm text-gray-900" key={index}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Paginator table={table} loading={loading} total={tableData.count}/>
    </div>
  )
}

export default BranchesDataTable