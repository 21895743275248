import Breadcrumbs from "../../components/Breadcrumbs";
import React, {useEffect, useMemo, useState} from "react";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/catalyst/table";
import {formatDateTime, shortDate, shortDateTime} from "../../helpers";
import _ from "lodash";
import {Badge} from "../../components/catalyst/badge";
import {Button} from "../../components/catalyst/button";
import {RotateCwIcon} from "lucide-react";
import {default as DeleteImport} from "./buttons/Delete"


const QUERY = gql`
  query getImports {
    me {
      imports {
        id
        createdAt
        total
        fails
        filename
        passes
        importErrors
        canDelete {
          value
        }
      }
    }
  }
`

const Files = ({}) => {
  const [data, setData] = useState([])
  const FileStatus = ({file}) => {
    const {total, passes, fails} = file
    const entries = passes + fails

    if (total === passes) return <Badge color="green" className="uppercase">success</Badge>
    if (entries < total) return <Badge color="sky" className="uppercase">importing</Badge>
    if (entries === total && fails > 0) return <Badge color="yellow" className="uppercase">finished with errors</Badge>

    return null
  }

  const [fetchFiles, {loading, error}] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
    variables: {},
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setData([])
      } else {
        setData(data.me.imports)
      }
    }
  })
  useEffect(() => {
    fetchFiles()
  }, []);


  const pages = [
    {name: "Files", href: `#`},
  ]

  const rows = useMemo(() => {
    if (loading || error || _.isNil(data)) return []
    return _.reverse(_.sortBy(data, "createdAt"))
  }, [data, loading, error])

  return (
    <div className="min-h-full flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Files
          </div>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0 gap-x-2">
          {/*<Button color="green" onClick={onImport} disabled={!canImport}>Import</Button>*/}
        </div>
      </div>

      <div>
        <Button onClick={fetchFiles} color="white">
          <RotateCwIcon className="size-5"/>
        </Button>
      </div>

      <div>
        <Table striped grid dense className="bg-white rounded-lg border ">
          <TableHead>
            <TableRow>
              <TableHeader>Filename</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Uploaded</TableHeader>
              <TableHeader className="text-right">Total</TableHeader>
              <TableHeader className="text-right">Success</TableHeader>
              <TableHeader className="text-right">Fail</TableHeader>
              <TableHeader className="">Errors</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((entry) => {
              const canDelete = entry.canDelete.value
              return (
                <TableRow key={entry.id}>
                  <TableCell className="flex flex-row space-x-2">
                    <div className="font-mono">{entry.filename}</div>
                    <DeleteImport importFile={entry} disabled={!canDelete} onDelete={fetchFiles}/>
                  </TableCell>
                  <TableCell><FileStatus file={entry}/> </TableCell>
                  <TableCell>{formatDateTime(entry.createdAt)}</TableCell>
                  <TableCell className="text-right">{entry.total}</TableCell>
                  <TableCell className="text-right">{entry.passes}</TableCell>
                  <TableCell className="text-right">{entry.fails}</TableCell>
                  <TableCell className="text-xs">
                    <ul className=" max-h-32 overflow-y-auto">
                      {entry.importErrors.map((err, err_idx) => <li key={err_idx}>{err}</li>)}
                    </ul>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>

    </div>
  )
}

export default Files