import React, {useState} from "react";
import {formatDate, shortDate} from "../../../../helpers";
import _ from "lodash";
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../../../../components/catalyst/dialog";
import {Description, Fieldset, Label, Legend} from "../../../../components/catalyst/fieldset";
import {Text} from "../../../../components/catalyst/text";
import {Radio, RadioField, RadioGroup} from "../../../../components/catalyst/radio";
import {Listbox, ListboxLabel, ListboxOption} from "../../../../components/catalyst/listbox";
import {Button} from "../../../../components/catalyst/button";
import VisitBadge from "../../view/VisitBadge";

export const TargetSelect = ({plan, visit, isOpen, onClose, onConfirm}) => {
  const [selected, setSelected] = useState("remove")

  const dateStr = formatDate(visit.route.date, {
    year: "numeric",
    month: 'short',
    day: '2-digit',
    weekday: "short",
    hour: undefined,
    minute: undefined
  })
  const state = visit.state.toLowerCase()
  const name = visit.branch.name
  const legend = <>The <VisitBadge visit={visit}/> visit to <b>{name}</b> is scheduled for <b>{dateStr}</b>.</>
  const lockedDates =
    _(plan.routes)
    .filter(r => r.locked)
    .map(r => r.date)
    .filter(d => d !== visit.route.date)
    .value()
  const canMove = !_.isEmpty(lockedDates) && visit.route.locked
  const [targetDate, setTargetDate] = useState(lockedDates[0])

  return (
    <Dialog open={isOpen} onClose={onClose} className="z-50" size={"2xl"}>
      <DialogTitle>Move visit to {name}</DialogTitle>
      <DialogBody className="">
        <Fieldset>
          <Legend>What would you like to do with this visit?</Legend>
          <Text>{legend}</Text>
          <RadioGroup name="visitAction" defaultValue="permit" value={selected} onChange={setSelected}>
            <RadioField>
              <Radio value="remove" color="indigo"/>
              <Label>Remove from plan</Label>
              <Description>
                The visit will be unscheduled and the branch will be removed from the plan.<br/>
                <u>If the visit was complete and you had notes or other records, they will be erased.</u>
              </Description>
            </RadioField>
            <RadioField disabled={!canMove}>
              <Radio value="move" color="indigo"/>
              <Label>Move to a specific date</Label>
              <Description>
                Manually reschedule this visit to a date of your choice.<br/>
                Both this date (<i>{dateStr}</i>) and the desired date must be locked.
                {canMove &&
                  <Listbox name="targetDate" value={targetDate} onChange={setTargetDate}>
                    {lockedDates.map((date, index) => (
                      <ListboxOption value={date} key={index}>
                        <ListboxLabel>{shortDate(date)}</ListboxLabel>
                      </ListboxOption>
                    ))}
                  </Listbox>
                }
              </Description>
            </RadioField>
            <RadioField>
              <Radio value="release" color="indigo"/>
              <Label>Release to another date</Label>
              <Description>
                The visit will remain in the plan and will be <i>automatically</i> rescheduled to a later unlocked date.
              </Description>
            </RadioField>
          </RadioGroup>
        </Fieldset>

      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>Cancel</Button>
        <Button onClick={() => onConfirm({operation: selected, date: targetDate, visitId: visit.id})} color="green">
          Move
        </Button>
      </DialogActions>
    </Dialog>
  )

}