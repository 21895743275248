import BaseCard from "../../components/BaseCard";
import {Table, TableBody, TableCell, TableRow} from "../../components/catalyst/table";
import React, {useMemo} from "react";
import {CheckIcon} from "@heroicons/react/24/solid";
import _ from "lodash";
import {XIcon} from "lucide-react";



const Guide = ({mapping, rows}) => {

  const fileSelected = (mapping, rows) => !_.isEmpty(mapping)
  const hasRows = (mapping, rows) =>  !_.isEmpty(rows)
  const addressAssigned = (mapping, rows) => _.some(mapping, x => x === "address")
  const nameAssigned = (mapping, rows) => _.some(mapping, x => x === "name")
  const clientAssigned = (mapping, rows) => _.some(mapping, x => x === "client")

  const rules = useMemo(() => {
    return [
      {
        check: fileSelected,
        positive: "File uploaded",
        negative: "Upload an excel file where the first row has the column names (header)"
      },
      {
        check: hasRows,
        positive: "The file uploaded has entries",
        negative: "Upload an excel file with more than one data entry"
      },
      {
        check: addressAssigned,
        positive: "Address column matched",
        negative: "Assign at least one column as address, e.g. street, city, state, postal code"
      },
      {
        check: nameAssigned,
        positive: "Branch name column matched",
        negative: "Assign one of the columns as the name of the branch"
      },
      {
        check: clientAssigned,
        positive: "Client name assigned",
        negative: "Assign one of the columns as the name of the client"
      }
    ]
  }, [mapping])

  if (_.isNil(mapping)) return null

  return (
    <div className="w-fit">
      <BaseCard title="Guide">
        <Table dense>
          <TableBody>
            {rules.map((rule, i) => {
              const pass = rule.check(mapping, rows)
              const icon = pass ? <CheckIcon className="text-green-500 font-bold size-5"/> :
                <XIcon className="text-red-500 size-5"/>
              const message = pass ? rule.positive : rule.negative
              return (
                <TableRow key={i}>
                  <TableCell>{icon}</TableCell>
                  <TableCell>{message}</TableCell>
                </TableRow>
              )
            })
            }
          </TableBody>
        </Table>
      </BaseCard>
    </div>
  )
}

export default Guide