import {TextQuote, Trash2Icon} from "lucide-react";
import {Button} from "../../../components/catalyst/button";
import React, {useState} from "react";
import {Alert, AlertActions, AlertDescription, AlertTitle} from "../../../components/catalyst/alert";
import {gql, useMutation} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import {Code, Strong} from "../../../components/catalyst/text";
import {formatDateTime} from "../../../helpers";
import _ from "lodash";

const DELETE_IMPORT = gql`
  mutation deleteImport($importId: ID!) {
    importDelete(input: {importId: $importId})
  }
`

const DeleteAlert = ({importFile, isOpen, onClose, onConfirm}) => {
  return (
    <Alert open={isOpen} onClose={onClose}>
      <AlertTitle>Are you sure you want to delete the imported file?</AlertTitle>
      <AlertDescription>
        You are about to delete the file <Code>{importFile.filename}</Code> created
        on <Code>{formatDateTime(importFile.createdAt)}</Code>.
        Deleting this file will also delete all its imported <Strong>places</Strong> and will affect
        the <Strong>plans</Strong> that used them.
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>Cancel</Button>
        <Button color="red" onClick={onConfirm}>Delete</Button>
      </AlertActions>
    </Alert>
  )
}


const Delete = ({importFile, onDelete, className, disabled}) => {
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [deleteImport] = useMutation(DELETE_IMPORT)

  const handleDeleteConfirm = () => {
    setDeleteAlert(false)
    deleteImport({variables: {importId: importFile.id}})
    .then(response => {
      const deleted = response.data.importDelete
      if (!deleted) throw new Error("Failed to delete.")

      enqueueSnackbar("Import file deleted.", {variant: "success"})
      if (_.isFunction(onDelete)) onDelete()
    })
    .catch(error => {
      enqueueSnackbar(`Could not delete the import file: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  return (
    <>
      <Button onClick={() => setDeleteAlert(true)} color="red" className={className} disabled={disabled}>
        <Trash2Icon className="size-3"/>
      </Button>
      <DeleteAlert importFile={importFile} isOpen={deleteAlert} onClose={() => setDeleteAlert(false)}
                   onConfirm={handleDeleteConfirm}/>
    </>
  )
}

export default Delete