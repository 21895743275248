// pattern: name, address, client
import _ from "lodash";

const NAME_FIELDS = ["name"]
const CLIENT_FIELDS = ["client"]
const ADDRESS_FIELDS = ["address", "city", "province", "country", "postal code"]

const transform = (row, mapping) => {

  let name = []
  let address = []
  let client = []

  row.forEach((value, index) => {
    const meaning = mapping[index]
    const trimmed = _.trim(value)
    if (_.includes(ADDRESS_FIELDS, meaning)) {
      address.push(trimmed)
    }
    if (_.includes(NAME_FIELDS, meaning)) {
      name.push(trimmed)
    }
    if (_.includes(CLIENT_FIELDS, meaning)) {
      client.push(trimmed)
    }
  })

  return {
    name: name.join(" "),
    address: address.join(" "),
    client: client.join(" ")
  }
}


export const transformer = (rawData, mapping) => {
  return rawData.map(row => transform(row, mapping))
}

