import {Dropdown, DropdownButton, DropdownItem, DropdownMenu} from "../../../../components/catalyst/dropdown";
import {ChevronDownIcon} from "@heroicons/react/16/solid";
import {VISIT_STATES} from "../../../../helpers";
import React from "react";
import {PLAN_DETAILS} from "../../../../fragments";
import {gql, useMutation} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import {STATE_BG_COLOR} from "./Visit";
import _ from "lodash";


const SET_STATES = gql`
  mutation setRouteVisitsState($id: ID!, $state: VisitState!) {
    routeSetVisitsState(input: {routeId: $id, state: $state}) {
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`

const VisitsStateDropdown = ({route, onChange}) => {
  const [setStates] = useMutation(SET_STATES)

  const onSetAllVisits = (state) => {
    setStates({variables: {id: route.id, state: state}})
    .then(response => {
      onChange(response.data.routeSetVisitsState)
    })
    .catch(error => {
      enqueueSnackbar(`Cannot set the visits states: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  // const disabled = _.isEmpty(route.visits) // FIXME

  return (
    <Dropdown >
      <DropdownButton outline>
        Set All Visits...
        <ChevronDownIcon/>
      </DropdownButton>
      <DropdownMenu className="z-50">
        {VISIT_STATES.map(state => (
          <DropdownItem key={state} onClick={() => onSetAllVisits(state)} className="capitalize">
            <div className={`size-3 ${STATE_BG_COLOR[state]} mr-2 border border-slate-300`}/>
            {state.toLowerCase()}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default VisitsStateDropdown


