import {MagnifyingGlassIcon} from '@heroicons/react/20/solid'

export const SearchBox = ({value, onChange}) => {

  const handleChange = (event) => {
    const text = event.currentTarget.value
    onChange(text)
  }

  return (
    <div>
      <label htmlFor="account-number" className="block text-sm font-medium leading-6 text-gray-900">
        Filter by
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="text"
          name="search"
          id="search"
          className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="enter search keywords..."
          onChange={handleChange}
          // onKeyUp={onKeyUpHandler}
          value={value}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </div>
      </div>
    </div>
  )
}
