import {Button} from "./catalyst/button";
import {GlobeAmericasIcon, PhoneIcon} from "@heroicons/react/24/solid";
import React from "react";
import _ from "lodash";

const WebButton = ({url}) => {
  const canClick = !_.isUndefined(url)

  return (
    <a href={url} target="_blank">
      <Button color="green" disabled={!canClick} className="cursor-pointer">
        <GlobeAmericasIcon className="size-5"/>
        Web
      </Button>
    </a>
  )
}

export default WebButton
