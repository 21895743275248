import {Link} from "react-router-dom";
import {HomeIcon} from '@heroicons/react/20/solid'
import {QuestionMarkCircleIcon} from "@heroicons/react/24/outline";


const Breadcrumbs = ({pages, helpUrl = ""}) => {

  return (
    <nav className="flex justify-between items-end" aria-label="Breadcrumb">
      <ol role="list" className="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li className="flex">
          <div className="flex items-center">
            <Link to={"/"} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="h-full w-6 flex-shrink-0 text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z"/>
              </svg>
              <div className="ml-4 flex gap-x-2 text-sm font-medium text-gray-500 hover:text-gray-700 items-end">
                {page.icon && <page.icon className="h-5"/>}
                <Link to={page.href}
                      className=""
                      aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </div>
          </li>
        ))}
      </ol>

      {/*<a href={helpUrl} target="_blank">*/}
      {/*  <div className="flex items-end">*/}
      {/*    <QuestionMarkCircleIcon className="h-8 w-8 bg-white rounded-full text-gray-500 hover:text-gray-700"/>*/}
      {/*  </div>*/}
      {/*</a>*/}
    </nav>
  )
}

export default Breadcrumbs
