// Spinner.js
import React from 'react';

const Spinner = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="animate-spin rounded-full size-32 border-b-2 border-gray-900"></div>
    </div>
  );
};

export default Spinner;
