import React from "react";
import {Status} from "./Status";
import {CheckCircle, XCircle} from "lucide-react";
import BaseInfoCard from "./BaseInfoCard";

const Yes = <CheckCircle className="size-4 stroke-green-500"/>
const No = <XCircle className="size-4 stroke-red-500"/>
const BranchInfoCard = ({branch}) => {
  const metadata = branch.place.metadata
  const tableData = {
    address: <a href={metadata.url} className="underline" target="_blank">{metadata.formatted_address}</a> ,
    phone: <a href={`tel:${metadata.formatted_phone_number}`} className="underline">{metadata.formatted_phone_number}</a> ,
    website: <a href={metadata.website} className="underline">{metadata.website}</a>,
    status: <span className="flex flex-row gap-x-2"><Status metadata={metadata}/>{metadata.business_status}</span>,
    accessible: metadata.wheelchair_accessible ? Yes : No
  }

  return (
    <BaseInfoCard
      title="Branch Information"
      tableData={tableData}
      className="max-w-2xl truncate"
    />
  )
}

export default BranchInfoCard