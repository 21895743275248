import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import Spinner from "../../../components/Spinner";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {formatDate, shortDate} from "../../../helpers";
import BranchInfoCard from "../../../components/BranchInfoCard";
import VisitInfoCard from "./VisitInfoCard";
import NotesCard from "../../../components/NotesCard";
import PhotosCard from "./PhotosCard";
import StaticMapCard from "../../../components/StaticMapCard";
import * as Sentry from "@sentry/react";
import ErrorPage from "../../../components/ErrorPage";
import {VISIT_ABILITIES, VISIT_DETAILS} from "../../../fragments";
import {enqueueSnackbar} from "notistack";

const QUERY = gql`
  query getVisit($id: ID!) {
    node(id: $id) {
      ... on Visit {
        id
        ...VisitDetails
      }
    }
  }
  ${VISIT_DETAILS}
  ${VISIT_ABILITIES}
`

const SET_NOTES = gql`
  mutation setVisitNotes($id: ID!, $notes:String!) {
    visitSetNotes(input: {notes: $notes,visitId: $id}) {
      id
      ...VisitDetails
    }
  }
  ${VISIT_DETAILS}
  ${VISIT_ABILITIES}
`

const VisitView = () => {
  const {id} = useParams()
  const [visit, setVisit] = useState(null)

  const [fetchVisit, {loading, error, data}] = useLazyQuery(QUERY, {
    variables: {id: id},
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setVisit({})
      } else {
        setVisit(data.node)
      }
    }
  })

  const [setNotes] = useMutation(SET_NOTES)

  useEffect(() => {
    fetchVisit()
  }, []);

  if (error) {
    Sentry.captureException(error)
    return <ErrorPage/>
  }
  if (loading || _.isNil(visit)) {
    return <Spinner/>
  }

  const onNotesChange = (notes) => {
    setNotes({variables: {id: id, notes: notes}})
    .then(response => setVisit(response.data.visitSetNotes))
    .catch(error => {
      enqueueSnackbar(`Could not set the notes for the visit: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  const pages = [
    {name: "Plans", href: `/plans`},
    {name: shortDate(visit.route.date), href: `/plans/${visit.route.plan.id}`},
    {name: visit.branch.place.metadata.name, href: "#"}
  ]

  return (
    <div className="min-h-full flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Visit to {visit.branch.name}
          </div>
        </div>
      </div>


      <div className="flex flex-row flex-wrap gap-4">
        <VisitInfoCard visit={visit}/>
        <NotesCard notes={visit.notes} onChange={onNotesChange}/>
        <BranchInfoCard branch={visit.branch}/>
        <PhotosCard/>
        <StaticMapCard place={visit.branch.place}/>
      </div>

    </div>
  )


}

export default VisitView