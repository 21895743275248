import _ from "lodash";
import {Table, TableBody, TableCell, TableRow} from "./catalyst/table";
import {Switch} from "./catalyst/switch";
import TimeListbox from "./TimeListbox";


function formatTime(number) {
  let numStr = number.toString();

  numStr = numStr.padStart(4, '0');
  const hours = numStr.slice(0, -2);
  const minutes = numStr.slice(-2);
  return `${parseInt(hours)}:${minutes}`;
}

const WeeklySchedule = ({schedule, onChange}) => {
  const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
  const canChange = _.isFunction(onChange)

  return (
    <div className="max-w-none ">
      <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow border ">
        <Table striped dense>
          <TableBody>
            {weekdays.map(day => {
              const scheduled = _.find(schedule, x => x.weekday === day)
              const missingDay = _.isUndefined(scheduled)
              const open = missingDay ? 0 : scheduled.open
              const close = missingDay ? 0 : scheduled.close
              const active = missingDay ? false : scheduled.active

              return (
                <TableRow key={day}>
                  <TableCell>
                    <Switch checked={active}
                            disabled={!canChange}
                            color="green"
                            onChange={value => onChange({active: value, weekday: day})}
                    />
                  </TableCell>
                  <TableCell className="font-bold text-left">{day}</TableCell>
                  <TableCell>
                    {active &&
                      <TimeListbox value={open}
                                   onChange={value => onChange({open: value, weekday: day})}
                                   disabled={!canChange}/>
                    }
                  </TableCell>
                  <TableCell>{active && <span>&rarr;</span>}</TableCell>
                  <TableCell>
                    {active &&
                      <TimeListbox value={close}
                                   onChange={value => onChange({close: value, weekday: day})}
                                   disabled={!canChange}/>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {/*<div className="text-xs font-light italic p-1">*/}
        {/*  last updated*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default WeeklySchedule