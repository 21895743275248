import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../auth/useAuth";


const Logout = () => {
  const {signOut} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    signOut()
    window.location.replace("/");
  }, [navigate, signOut]);

  return <div>Logging out...</div>;
};

export default Logout;
