import React from "react";
import {CheckCircle, XCircle} from "lucide-react";
import {shortDate} from "../../../helpers";
import BaseInfoCard from "../../../components/BaseInfoCard";
import VisitBadge from "../view/VisitBadge";
import {Link} from "react-router-dom"

const Yes = <CheckCircle className="size-4 stroke-green-500"/>
const No = <XCircle className="size-4 stroke-red-500"/>
const VisitInfoCard = ({visit}) => {
  const metadata = visit.branch.place.metadata
  const branch = visit.branch
  const place = branch.place

  const tableData = {
    branch: <Link to={`/places/${branch.id}`} className="underline">{place.name}</Link>,
    date: <Link to={`/plans/${visit.route.plan.id}`} className="underline" >{shortDate(visit.route.date)}</Link> ,
    status: <VisitBadge visit={visit}/>
  }

  return <BaseInfoCard title="Visit Information" tableData={tableData}/>
}

export default VisitInfoCard