import {PencilIcon} from "lucide-react";
import React, {useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import {PLAN_DETAILS} from "../../../fragments";
import {Dialog, DialogActions, DialogBody, DialogTitle} from "../../../components/catalyst/dialog";
import PickDate from "../create/PickDate";
import {buildDate} from "../../../helpers";
import {Button} from "../../../components/catalyst/button";

const UPDATE_PLAN = gql`
  mutation updatePlan($planId: ID!, $date:ISO8601Date!) {
    planUpdate(input: {planId: $planId, startDate: $date}) {
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`

const UpdateDialog = ({plan, isOpen, onClose, onConfirm}) => {
  const [planStartDate, setPlanStartDate] = useState(buildDate(plan.dateRange.start))

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Plan</DialogTitle>
      <DialogBody className="flex flex-col gap-y-5">
        <PickDate planStartDate={planStartDate} setPlanStartDate={setPlanStartDate}/>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>Cancel</Button>
        <Button onClick={() => onConfirm(planStartDate)} color="green">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}


const Update = ({plan, onUpdate, className}) => {
  const [updateDialog, setUpdateDialog] = useState(false)
  const [updatePlan] = useMutation(UPDATE_PLAN)

  const handleUpdateConfirm = (newDate) => {
    setUpdateDialog(false)
    updatePlan({variables: {planId: plan.id, date: newDate}})
    .then(response => {
      enqueueSnackbar("Plan updated.", {variant: "success"})
      const thePlan = response.data.planUpdate
      onUpdate(thePlan)
    })
    .catch(error => {
      enqueueSnackbar(`Could not update the plan: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  return (
    <>
      <button onClick={() => setUpdateDialog(true)} color="green" className={className}>
        <PencilIcon className="size-5 mr-1"/>
        Edit
      </button>
      <UpdateDialog plan={plan} isOpen={updateDialog} onClose={() => setUpdateDialog(false)}
                    onConfirm={handleUpdateConfirm}/>
    </>
  )
}

export default Update