import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Root from "./Root";
import {GoogleOAuthProvider} from "@react-oauth/google";
import AuthorizedApolloProvider from "./auth/AuthorizedApolloProvider";
import * as Sentry from "@sentry/react";
import {closeSnackbar, SnackbarProvider} from "notistack";

import './index.css';
import 'react-day-picker/dist/style.css';
import {Button} from "./components/catalyst/button";
import {Crisp} from "crisp-sdk-web";
import ReactPixel from "react-facebook-pixel";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2644c9c2baadab78ac84720b9f048e6c@o1040791.ingest.sentry.io/4506815997280256",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin, `${process.env.REACT_APP_API_HOST}//graphql`],
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
      Sentry.feedbackIntegration({
        colorScheme: "system",
      }),
    ],
    environment: process.env.NODE_ENV,
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/route-mapper-prototype\.onrender\.com\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const fallback = (
  <p>
    There has been an error. I've been notified and am looking into it.
    Please try <a href="/" style={{textDecoration: "underline"}}>reloading the page</a> in a few moments.
  </p>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
const googleClientId = "368827421648-hsm2a7bpkjavmhbg0eb693hseud0kk3e.apps.googleusercontent.com"

Crisp.configure("f1589b59-3a5b-4163-9e42-5a81d511a3f6", {
  autoload: false
});

ReactPixel.init('1431838240783416'); // TODO: advanced matching https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching

root.render(
  // <React.StrictMode> // remove due to InfoWindow bug: https://github.com/tomchentw/react-google-maps/issues/1077
  <Sentry.ErrorBoundary
    fallback={fallback}>
    <GoogleOAuthProvider clientId={googleClientId}>
      <SnackbarProvider maxSnack={5}
                        autoHideDuration={5000}
                        action={(snackbarId) => (
                          <Button outline onClick={() => closeSnackbar(snackbarId)} color="white">Dismiss</Button>
                        )}
                        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      >
        <BrowserRouter>
          <AuthorizedApolloProvider>
            <Root/>
          </AuthorizedApolloProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </GoogleOAuthProvider>
  </Sentry.ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
