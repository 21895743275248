import React, {useState} from "react";
import {gql, useQuery} from "@apollo/client";
import Breadcrumbs from "../../components/Breadcrumbs";
import NoBranches from "../../components/NoBranches";
import {SearchBox} from "../../components/SearchBox";
import {classNames} from "../../helpers";
import BranchesDataTable from "../../components/BranchesDataTable";
import BranchesMapPanel from "./map";

const QUERY = gql`
  query Dashboard {
    me {
      clients {
        id
      }
      branches {
        id
      }
    }
  }
`

const Branches = () => {
  const tabs = ['map', "list"]
  const [tab, setTab] = useState(tabs[0])
  const [query, setQuery] = useState("")

  const {loading, error, data} = useQuery(QUERY, {fetchPolicy: "network-only"})
  const showEmpty = !loading && !error && !_.isNil(data) && _.isEmpty(data.me.branches)

  const pages = [
    {name: "Places", href: `#`},
  ]

  const activeBranches = []


  return (
    <div className="min-h-full flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Places
          </div>
        </div>
      </div>

      {showEmpty && <NoBranches/>}

      {!showEmpty &&
        <div className="flex flex-col gap-y-4">
          <SearchBox value={query} onChange={setQuery}/>

          <div className="sm:block">
            <div className="border-b border-gray-300">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((theTab) => {
                  const current = tab === theTab
                  return (
                    <div
                      key={theTab}
                      className={classNames(
                      current
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium capitalize'
                    )}
                         onClick={() => setTab(theTab)}
                    >
                      {theTab}
                    </div>
                  )
                })}
              </nav>
            </div>
          </div>


          <div className={tab === "map" ? '' : 'hidden'}>
            <BranchesMapPanel query={query}/>
          </div>

          <div className={tab === "list" ? '' : 'hidden'}>
            <BranchesDataTable query={query}/>
          </div>

        </div>
      }
    </div>
  )


}

export default Branches