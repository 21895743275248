import {gql, useLazyQuery, useMutation} from "@apollo/client";
import React, {useMemo, useRef, useState} from "react";
import _, {debounce} from "lodash";
import {DrawingManager, GoogleMap, InfoWindow, Marker, Polyline} from "@react-google-maps/api";
import {buildDate, categoryColors, decodePolyline} from "../../../helpers";
import {SchedulableInfoWindow, ScheduledInfoWindow} from "./BranchInfoWindow";
import {addDays, isAfter} from "date-fns";
import chroma from "chroma-js";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import {PLAN_DETAILS} from "../../../fragments";

const BRANCHES_QUERY = gql`
  query getBranches($bbox: BoundingBoxInput!) {
    branches(boundingBox: $bbox) {
      id
      name
      place {
        point {
          lat
          lng
        }
        name
        metadata
      }
      client {
        settings
      }
    }
  }
`
const ADD_BRANCHES = gql`
  mutation addBranch($planId: ID!, $branchesIds: [ID!]!) {
    planAddBranches(input: {planId: $planId, branchIds: $branchesIds}) {
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`
const REMOVE_BRANCH = gql`
  mutation removeBranch($planId: ID!, $branchId: ID!) {
    planRemoveBranch(input: {planId: $planId, branchId: $branchId}) {
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`

const mapContainerStyle = {
  width: '100%',
  height: '75lvh',
};

const PlanMap = ({plan, selectedRoute, setSelectedRoute, onPlanChange, onViewportChange}) => {

  const mapRef = useRef(null);
  const [branches, setBranches] = useState([])
  const [focusBranch, setFocusBranch] = useState(null)
  const [focusLocation, setFocusLocation] = useState(plan?.routes[0].starting.metadata.geometry.location)
  const overlaysRef = useRef([])
  const selectedBranches = useRef([])

  const [addBranches] = useMutation(ADD_BRANCHES)
  const [removeBranch] = useMutation(REMOVE_BRANCH)

  const [fetchBranches, {loading, error, data}] = useLazyQuery(BRANCHES_QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setBranches([])
      } else {
        setBranches(data.branches)
        onViewportChange(`Branches on map: ${data.branches.length}`) // TODO: move to its own useEffect ?
      }
    }
  })

  const freeBranches = useMemo(() => {
    const planBranches = _(plan.routes).flatMap("visits").flatMap("branch").value()
    return _.differenceWith(branches, planBranches, (a, b) => a.id === b.id)
  }, [branches, plan])

  const onMapLoad = (map) => {
    mapRef.current = map

    const clearButton = createMapButton("Clear Selection")
    clearButton.addEventListener("click", () => {
      clearOverlays()
    });

    const addToPlanButton = createMapButton("Add to Plan")
    addToPlanButton.addEventListener("click", () => {
      addSelectedToPlan()
    })

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(clearButton)
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(addToPlanButton)

    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds)
  }

  const fetchBranchesDebounced = debounce((bounds) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const bbox = {
      ne: {lat: ne.lat(), lng: ne.lng()},
      sw: {lat: sw.lat(), lng: sw.lng()}
    }
    fetchBranches({variables: {bbox: bbox}});
  }, 500); // Adjust the debounce time (ms) as needed

  const onBoundsChanged = () => {
    const bounds = mapRef.current.getBounds()
    if (bounds) {
      fetchBranchesDebounced(bounds)
    }
  }

  const scheduledBranchIcon = (color, isSelected) => ({
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    fillOpacity: isSelected ? 1 : 0.5,
    scale: 10,
    strokeColor: chroma(color).darken(1).hex(),
    strokeWeight: isSelected ? 2 : 1,
  })

  const unschedulableBranchIcon = (branch) => {
    let branchColor = "#cbd5e1"

    if (!_.isNil(branch.lastVisit)) {
      const planDate = buildDate(route.plan.date)
      const timeHorizonDate = addDays(planDate, -visitHorizon)
      const lastVisitDate = buildDate(branch.lastVisit.route.plan.date)

      if (isAfter(lastVisitDate, planDate)) {
        branchColor = "#ef4444"
      }
    }

    return ({
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: branchColor,
      fillOpacity: 1,
      scale: 8,
      strokeColor: chroma(branchColor).darken(2).hex(),
      strokeWeight: 3,
    })
  }

  const schedulableBranchIcon = (branch) => {
    let branchColor = branch.client.settings.main_color

    return ({
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: branchColor,
      fillOpacity: 1,
      scale: 8,
      strokeColor: chroma(branchColor).darken(1).hex(),
      strokeWeight: 3,
    })
  }

  const terminusIcon = (color) => ({
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: "#ffffff",
    fillOpacity: 1,
    scale: 8,
    strokeColor: color,
    strokeWeight: 3,
  })

  const overlayColors = {
    fillColor: `#2196F3`,
    strokeColor: `#2196F3`,
    fillOpacity: 0.3,
    strokeWeight: 2,
    clickable: false,
    editable: false,
    draggable: false,
    zIndex: 1
  }

  const onAddToPlan = (branch) => {
    setFocusBranch(null)
    setFocusLocation(branch.place.point)
    addBranches({variables: {planId: plan.id, branchesIds: [branch.id]}})
    .then(response => {
      const thePlan = response.data.planAddBranches
      onPlanChange(thePlan)
    })
    .catch(error => {
      enqueueSnackbar(`Could not add the branch: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  const onRemoveFromPlan = (branch) => {
    setFocusBranch(null)
    setFocusLocation(branch.place.point)
    removeBranch({variables: {planId: plan.id, branchId: branch.id}})
    .then(response => {
      const thePlan = response.data.planRemoveBranch
      onPlanChange(thePlan)
    })
    .catch(error => {
      enqueueSnackbar(`Could not remove the branch: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  const onRectangle = (rectangle) => {
    const selected = branches.filter(branch => {
      const point = new google.maps.LatLng(branch.place.point)
      const bounds = rectangle.getBounds()
      return bounds.contains(point)
    })
    selectedBranches.current.push(selected)
  }

  const onCircle = (circle) => {
    const selected = branches.filter(branch => {
      const point = new google.maps.LatLng(branch.place.point)
      const center = circle.getCenter()
      const radius = circle.getRadius()

      const distance = google.maps.geometry.spherical.computeDistanceBetween(point, center);
      return distance <= radius;
    })
    selectedBranches.current.push(selected)
  }

  const onOverlay = (overlay) => {
    overlaysRef.current.push(overlay)
  }

  const clearOverlays = () => {
    overlaysRef.current.forEach(item => {
      const overlay = item.overlay
      if (overlay.setMap) {
        overlay.setMap(null);
      }
    });
    overlaysRef.current = [];
    selectedBranches.current = []
  }

  const addSelectedToPlan = () => {
    const flattened = _.flatten(selectedBranches.current)
    const unique = _.uniqBy(flattened, "id")

    addBranches({variables: {planId: plan.id, branchesIds: unique.map(x => x.id)}})
    .then(response => {
      clearOverlays()
      const thePlan = response.data.planAddBranches
      onPlanChange(thePlan)
    })
    .catch(error => {
      enqueueSnackbar(`Could not add the branch: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })

  }

  const createMapButton = (label) => {
    const button = document.createElement("button");
    button.textContent = label;
    button.classList.add("custom-map-control-button");
    // Style your button with CSS here or via JavaScript
    button.style.backgroundColor = "#fff";
    button.style.border = "none";
    button.style.outline = "none";
    // button.style.width = "100px";
    button.style.padding = "4px";
    button.style.fontFamily = "Inter,Roboto,Arial,sans-serif";
    button.style.fontSize = "14px";
    button.style.boxShadow = "0 1px 4px rgba(0,0,0,0.3)";
    button.style.cursor = "pointer";
    button.style.margin = "5px";
    button.style.textAlign = "center";
    return button;
  }

  return (
    <div className="flex-1 max-w-full min-w-full border border-slate-500">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        onLoad={onMapLoad}
        center={focusLocation}
        onBoundsChanged={onBoundsChanged}
        zoom={13}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        <DrawingManager
          drawingMode={null}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              drawingModes: ["rectangle", "circle"]
            },
            rectangleOptions: overlayColors,
            circleOptions: overlayColors,
          }}
          onRectangleComplete={onRectangle}
          onCircleComplete={onCircle}
          onOverlayComplete={onOverlay}
        />


        {freeBranches.map((branch, index) => {
          return (
            <Marker
              key={index}
              position={branch.place.point}
              onClick={() => setFocusBranch(branch)}
              icon={schedulableBranchIcon(branch)}
            >
              {focusBranch?.id === branch.id && (
                <InfoWindow onCloseClick={() => setFocusBranch(null)}>
                  <SchedulableInfoWindow branch={branch} onAdd={() => onAddToPlan(branch)}/>
                </InfoWindow>
              )
              }
            </Marker>
          )
        })}

        {plan.routes.map((route, index) => {
          const directions = route.directions[0]
          const orderedVisits = directions.waypoint_order.map(index => route.visits[index]).filter(x => !_.isNil(x))

          const isSelected = route.id === selectedRoute?.id
          const routeOpacity = isSelected ? 0.85 : 0.60
          const routeWeight = isSelected ? 8 : 4

          return (
            <>
              <Marker position={route.starting.metadata.geometry.location} icon={terminusIcon(categoryColors[index])}/>
              <Marker position={route.ending.metadata.geometry.location} icon={terminusIcon(categoryColors[index])}/>

              <Polyline
                path={decodePolyline(route.directions[0].overview_polyline.points)}
                options={{
                  strokeColor: categoryColors[index],
                  strokeOpacity: routeOpacity,
                  strokeWeight: routeWeight,
                }}
                onClick={() => setSelectedRoute(route)}
              />

              {orderedVisits.map((visit, i) => {
                const {branch} = visit
                return (
                  <Marker
                    key={branch.id}
                    position={branch.place.point}
                    onClick={() => {
                      setFocusBranch(branch)
                      setSelectedRoute(route)
                    }}
                    icon={scheduledBranchIcon(categoryColors[index], isSelected)}
                    label={{
                      text: String.fromCharCode(65 + i),
                      color: 'white',
                      fontSize: '12px',
                    }}
                  >
                    {focusBranch?.id === branch.id && (
                      <InfoWindow onCloseClick={() => setFocusBranch(null)}>
                        <ScheduledInfoWindow visit={visit} onRemove={() => onRemoveFromPlan(branch)}/>
                      </InfoWindow>
                    )
                    }
                  </Marker>
                )
              })}
            </>
          )
        })}

      </GoogleMap>
    </div>
  )
}

export default PlanMap