import {Link} from "./components/catalyst/link";
import _ from "lodash";
import {addMinutes, isWithinInterval, parseISO, startOfDay} from "date-fns";

export const VISIT_STATES = ["PLANNED", "AUTHORIZED", "CONFIRMED", "COMPLETED", "CANCELLED"]

export const categoryColors = [
  "#1f77b4", // Muted blue
  "#ff7f0e", // Safety orange
  "#2ca02c", // Cooked asparagus green
  "#d62728", // Brick red
  "#9467bd", // Muted purple
  "#8c564b", // Chestnut brown
  "#e377c2", // Raspberry yogurt pink
  "#7f7f7f", // Middle gray
  "#bcbd22", // Curry yellow-green
  "#17becf", // Blue-teal
  "#9edae5", // Light blue
  "#17a2b8", // Bluish teal
  "#2f4f4f", // Dark slate gray
  "#8b0000", // Dark red
  "#e6ab02", // Gold
  "#7b68ee", // Medium slate blue
  "#c49c94", // Pinkish brown
  "#b5cf6b", // Olive green
  "#f7b6d2", // Pale pink
  "#cedb9c"  // Pale green
];


export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function clusterName(cluster) {
  return String.fromCharCode(cluster.id + 65)
}


export function sumViewports(locations) {
  const viewports = locations.map(l => l.geometry.viewport)

  // Initialize with the first viewport's coordinates if available, or set to a default
  let combinedViewport = viewports.length > 0 ? {
    northeast: {lat: viewports[0].northeast.lat, lng: viewports[0].northeast.lng},
    southwest: {lat: viewports[0].southwest.lat, lng: viewports[0].southwest.lng}
  } : {northeast: {lat: 0, lng: 0}, southwest: {lat: 0, lng: 0}};

  // Iterate over each viewport to adjust the combined bounding box
  viewports.forEach(vp => {
    combinedViewport.northeast.lat = Math.max(combinedViewport.northeast.lat, vp.northeast.lat);
    combinedViewport.northeast.lng = Math.max(combinedViewport.northeast.lng, vp.northeast.lng);
    combinedViewport.southwest.lat = Math.min(combinedViewport.southwest.lat, vp.southwest.lat);
    combinedViewport.southwest.lng = Math.min(combinedViewport.southwest.lng, vp.southwest.lng);
  });

  return combinedViewport;
}

export function calculateViewportCenter(viewport) {
  const centerLat = (viewport.northeast.lat + viewport.southwest.lat) / 2;
  const centerLng = (viewport.northeast.lng + viewport.southwest.lng) / 2;

  return {lat: centerLat, lng: centerLng};
}

export function decodePolyline(encoded) {
  let points = [];
  let index = 0, len = encoded.length;
  let lat = 0, lng = 0;

  while (index < len) {
    let b, shift = 0, result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    let dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
    lat += dlat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    let dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
    lng += dlng;

    points.push({lat: (lat / 1E5), lng: (lng / 1E5)});
  }

  return points;
}

export const formatDate = (date, options = {}) => {
  let x = date
  if (_.isString(date)) x = startOfDay(parseISO(date))
  if (_.isNil(x) || _.isNaN(date)) return null

  const defaultOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: "numeric",
    minute: "numeric"
  }

  const format = new Intl.DateTimeFormat("en-US", _.assign({}, defaultOptions, options)).format
  return format(x)
}

export const formatDateRange = (range, options = {}) => {
  const {start, finish} = range
  if (start === finish) return formatDate(start, options)
  return `${formatDate(start, options)} - ${formatDate(finish, options)}`
}

export const shortDate = (date) => {
  return formatDate(date, {
    year: "numeric",
    month: 'short',
    day: '2-digit',
    weekday: undefined,
    hour: undefined,
    minute: undefined
  })
}

export const formatDateTime = (date, options = {}) => {
  let x = date
  if (_.isString(date)) x = parseISO(date)
  if (_.isNil(x) || _.isNaN(date)) return null

  const defaultOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }
  const format = new Intl.DateTimeFormat("en-US", _.assign({}, defaultOptions, options)).format
  return format(x)
}

export const shortDateRange = (range) => {
  const {start, finish} = range
  if (start === finish) return shortDate(start)
  return `${shortDate(start)} - ${shortDate(finish)}`
}

export const formatTime = (dateTime) => {
  const options = {
    hour: 'numeric',      // Display the hour
    minute: 'numeric',    // Display the minute
    // second: 'numeric',    // Optionally, display the second
    // hour12: true          // Use 12-hour time (use `false` for 24-hour format)
  };

  const timeFormatter = new Intl.DateTimeFormat("en-US", options).format
  return timeFormatter(dateTime)
}

export const buildDate = (str) => startOfDay(parseISO(str))

export const humanizeDuration = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  const hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  const mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;

  let result = "";
  if (days) result += `${days} day${days > 1 ? 's' : ''} `;
  if (hrs) result += `${hrs} hour${hrs > 1 ? 's' : ''} `;
  if (mnts) result += `${mnts} min${mnts > 1 ? 's' : ''} `;
  // if (seconds || (!days && !hrs && !mnts)) result += `${seconds} sec${seconds > 1 ? 's' : ''}`;

  // Remove trailing comma if present
  return result.replace(/, $/, '');
}

export const humanizeDistance = (meters, precision = 1) => {
  if (meters < 1000) {
    return `${meters}m`;
  } else {
    const kilometers = (meters / 1000).toFixed(precision);
    return `${kilometers} km`;
  }
}

export const leftButtonStyle = "relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-normal text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 capitalize disabled:text-gray-300"
export const innerButtonStyle = "relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-normal text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10  capitalize  disabled:text-gray-300"
export const rightButtonStyle = "relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-normal text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 capitalize disabled:text-gray-300"

const WEEKDAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
export const getUsersDailyHours = (user, date) => {
  const weekday = date.getDay()
  const dayHours = user.dailyHours.find(w => w.weekday === WEEKDAYS[weekday])
  if (_.isNil(dayHours)) return null
  return {
    start: addMinutes(date, dayHours.open),
    end: addMinutes(date, dayHours.close),
    active: dayHours.active
  }
}

export const isVisitInWorkingHours = (user, visit, time) => {
  const date = buildDate(visit.route.date)
  const workInterval = getUsersDailyHours(user, date)
  if (_.isNil(workInterval)) return false

  const visitSpan = parseInt(visit.timeSpan.split("_")[1])
  const visitEnd = addMinutes(time, visitSpan)
  return isWithinInterval(time, workInterval) && isWithinInterval(visitEnd, workInterval)
}