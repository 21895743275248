import {Link} from "react-router-dom";
import React from "react";
import {shortDate} from "../../../helpers";
import {LockClosedIcon} from "@heroicons/react/16/solid";


const BranchLink = ({branch}) => (
  <Link to={`/places/${branch.id}`} className="underline cursor-pointer">
    <div className="font-bold">{branch.name}</div>
    <div className="font-extralight text-slate-500">{branch.place.metadata.formatted_address}</div>
  </Link>
)

const VisitLink =({visit}) => (
  <Link to={`/visits/${visit.id}`} className="underline cursor-pointer">
    <div className="font-bold">{visit.branch.name}</div>
    <div className="font-extralight text-slate-500">{visit.branch.place.metadata.formatted_address}</div>
  </Link>
)

export const UnschedulableInfoWindow = ({branch}) => {
  const plan = branch.lastVisit.route.plan
  return (
    <div className="text-xs text-slate-700">
      <BranchLink branch={branch}/>
      <div className="mt-2">Plan: {shortDate(plan.date)}</div>
    </div>
  )
}


export const SchedulableInfoWindow = ({branch, onAdd}) => {
  return (
    <div className="text-xs text-slate-700">
      <BranchLink branch={branch}/>
      <div className="underline cursor-pointer mt-2" onClick={onAdd}>add to plan</div>
    </div>
  )
}

export const ScheduledInfoWindow = ({visit, onRemove}) => {
  return (
    <div className="text-xs text-slate-700">
      <VisitLink visit={visit}/>
      <div className="mt-2 flex gap-x-2 items-center">
        {shortDate(visit.route.date)}
        {visit.route.locked && <LockClosedIcon className="size-3"/> }
      </div>
      <div className="underline cursor-pointer" onClick={onRemove}>remove from plan</div>
    </div>
  )
}





