import {gql, useLazyQuery} from "@apollo/client";
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import _ from "lodash";
import Spinner from "../../../components/Spinner";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../../components/catalyst/table";
import {Status} from "../../../components/Status";
import ErrorPage from "../../../components/ErrorPage";
import * as Sentry from "@sentry/react";

const QUERY = gql`
  query getClient($id: ID!) {
    node(id: $id) {
      ... on Client {
        branches {
          id
          metadata
          place {
            metadata
            id
            uuid
          }
        }
      }
    }
  }
`

const Branches = ({}) => {
  const {id} = useParams()
  const navigate = useNavigate()

  const [fetchClientBranches, {loading, error, data}] = useLazyQuery(QUERY, {
    variables: {id: id},
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    fetchClientBranches()
  }, []);

  if (loading || error || _.isUndefined(data)) {
    if (error) {
      Sentry.captureException(error)
      return <ErrorPage/>
    } else {
      return <Spinner/>
    }
  }

  const {node} = data
  const {branches} = node

  return (
    <Table dense striped className="border rounded bg-white">
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Address</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {branches.map((branch) => {
          const data = branch.place.metadata
          return (
            <TableRow key={branch.id}>
              <TableCell className="font-medium flex flex-row gap-x-2">
                <Status metadata={data}/>
                <Link to={`/places/${branch.id}`} className="underline">
                  {data.name}
                </Link>
              </TableCell>
              <TableCell>{data.formatted_address}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )

}

export default Branches