import React from 'react';
import {Listbox, ListboxLabel, ListboxOption} from "./catalyst/listbox";
import {gql, useMutation} from "@apollo/client";
import {PLAN_DETAILS} from "../fragments";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";

function createTimeOptions(minutesIncrement = 30) {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += minutesIncrement) {
      const value = hour * 60 + minute;
      const label = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      options.push({value, label});
    }
  }
  return options;
}

const TimeListbox = ({value, onChange, className, disabled}) => {
  const timeOptions = createTimeOptions();

  return (
    <div className={className}>
      <Listbox value={value} onChange={onChange} disabled={disabled}>
        {timeOptions.map((option) => (
          <ListboxOption key={option.value} value={option.value}>
            <ListboxLabel>{option.label}</ListboxLabel>
          </ListboxOption>
        ))}
      </Listbox>
    </div>
  )
}

export default TimeListbox;
