import {gql, useLazyQuery, useMutation} from "@apollo/client";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import Spinner from "../../components/Spinner";
import Breadcrumbs from "../../components/Breadcrumbs";
import WeeklySchedule from "../../components/WeeklySchedule";
import ErrorPage from "../../components/ErrorPage";
import * as Sentry from "@sentry/react";
import {formatDuration} from "date-fns";
import {USER_ABILITIES, USER_DETAILS} from "../../fragments";
import Autocomplete from "react-google-autocomplete";
import {enqueueSnackbar} from "notistack";
import CalendarSetting from "./CalendarSetting";

const api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const QUERY = gql`
  query getSettings {
    me {
      id
      ...UserDetails
      ...UserAbilities
    }
  }
  ${USER_DETAILS}
  ${USER_ABILITIES}
`

const SET_PERMANENT_BASE = gql`
  mutation setPermanentBase($placeId: String!) {
    userSetPermanentBase(input: {placeUuid: $placeId}) {
      id
      ...UserDetails
      ...UserAbilities
    }
  }
  ${USER_DETAILS}
  ${USER_ABILITIES}
`

const SET_DAILY_HOUR = gql`
  mutation setDailyHour($weekday:Weekday!, $active:Boolean, $open: Int, $close: Int) {
    userSetWeekday(input: {weekday: $weekday, active: $active, openMinutes: $open, closeMinutes: $close}) {
      active
      open
      close
      weekday
    }
  }
`

const Settings = ({}) => {
  const [user, setUser] = useState(null)
  const [setDailyHour] = useMutation(SET_DAILY_HOUR)
  const [setBase] = useMutation(SET_PERMANENT_BASE)

  const [fetchSettings, {loading, error, data}] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setUser(null)
      } else {
        setUser(data.me)
      }
    }
  })

  useEffect(() => {
    fetchSettings()
  }, []);

  if (loading || error || _.isNil(user)) {
    if (error) {
      Sentry.captureException(error)
      return <ErrorPage/>
    } else {
      return <Spinner/>
    }
  }

  const onPlaceChange = (place) => {
    setBase({variables: {placeId: place.place_id}})
    .then(response => {
      const theUser = response.data.userSetPermanentBase
      setUser(theUser)
      enqueueSnackbar(`Your permanent base is set.`, {variant: "success"})
    })
    .catch(error => {
      enqueueSnackbar(`Could not set the permanent base: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  const onWeekdayChange = (value) => {
    setDailyHour({variables: value})
    .then(response => {
      const newDailyHours = response.data.userSetWeekday
      setUser((prevUser) => {
        let newUser = structuredClone(prevUser);
        newUser.dailyHours = newDailyHours
        return newUser
      })
    })
    .catch(error => {
      enqueueSnackbar("Could not set the daily hours for the schedule.", {variant: "error"})
      Sentry.captureException(error)
    })
  }



  const Base = ({user}) => (
    <Autocomplete
      disabled={!user.canSetBase.value}
      apiKey={api_key}
      onPlaceSelected={onPlaceChange}
      options={{
        types: ['geocode', "establishment"],
        componentRestrictions: {country: "ca"}
      }}
      defaultValue={user.permanentBase?.metadata.formatted_address}
      className=" w-full rounded px-2 py-2 border"
      placeholder="Your main address..."
    />
  )

  const pages = [
    {name: "Settings", href: `#`},
  ]

  const timeHorizon = formatDuration({days: user.settings.visit_horizon})

  const tableData = [
    {name: "Permanent Base", value: <Base user={user}/>},
    {name: "Visit time horizon", value: <div className="">{timeHorizon}</div>},
    {name: "Working hours", value: <WeeklySchedule schedule={user.dailyHours} onChange={onWeekdayChange}/>},
    {name: "Calendar", value: <CalendarSetting url={user.calendarUrl} />}

  ]

  return (
    <div className="min-h-full flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Settings
          </div>
        </div>
      </div>

      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          {tableData.map((row, index) => {

            return (
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" key={index}>
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {row.name}
                </dt>
                <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {row.value}
                </dd>
              </div>
            )
          })}
        </dl>
      </div>


    </div>
  )
}

export default Settings