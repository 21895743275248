import {gql} from "@apollo/client";

export const USER_ABILITIES = gql`
  fragment UserAbilities on User {
    canCreatePlan {
      value
      message
      reasons {
        details
        fullMessages
      }
    }
    canSetBase {
      value
      message
      reasons {
        details
      }
    }
    canAddBranch {
      value
      message
      reasons {
        details
      }
    }
  }
`
export const PLAN_ABILITIES = gql`
  fragment PlanAbilities on Plan {
    canAddBranch {
      value
    }
    canRemoveBranch {
      value
    }
  }
`
export const ROUTE_ABILITIES = gql`
  fragment RouteAbilities on Route {
    canSetStartOffset {
      value
    }
    canSetTerminal {
      value
    }
    canSetVisitsState {
      value
    }
    canSetVisitState {
      value
    }
    canToggleLock {
      value
    }
  }
`

export const VISIT_ABILITIES = gql`
  fragment VisitAbilities on Visit {
    canMove {
      value
    }
    canSetNotes {
      value
    }
    canSetState {
      value
    }
    canSetTimestamp {
      value
    }
  }
`

export const USER_DETAILS = gql`
  fragment UserDetails on User {
    name
    calendarUrl
    pictureUrl
    email
    settings
    permanentBase {
      metadata
      uuid
    }
    dailyHours {
      active
      weekday
      open
      close
    }
  }
`
export const VISIT_DETAILS = gql`
  fragment VisitDetails on Visit {
    state
    notes
    timeSpan
    route {
      id
      date
      locked
      plan {
        id
      }
    }
    branch {
      id
      name
      client {
        id
        name
        fullName
      }
      place {
        id
        name
        point {
          lat 
          lng
        }
        metadata
      }
    }
  }
`
export const BRANCH_FIELDS = gql`
  fragment BranchFields on Branch {
    id
    name
    metadata
    client {
      settings
    }
    place {
      name
      metadata
      id
      uuid
    }
    lastVisit {
      route {
        date
      }
    }
  }
`
export const PLAN_BRANCHES = gql`
  fragment BranchesDetails on Plan {
    schedulableBranches {
      ...BranchFields
    }
    unschedulableBranches {
      ...BranchFields
    }
  }
  ${BRANCH_FIELDS}
`
export const ROUTE_DETAILS = gql`
  fragment RouteDetails on Route {
    date
    startOffset
    locked
    starting {
      id 
      point {
        lng
        lat
      }
      metadata
    }
    ending {
      id
      point {
        lat
        lng
      }
      metadata
    }
    directions
    visits {
      id
      ...VisitDetails
      ...VisitAbilities
    }
  }
  ${VISIT_DETAILS}
  ${VISIT_ABILITIES}
`
export const PLAN_DETAILS = gql`
  fragment PlanDetails on Plan {
    dateRange {
      start
      finish
    }
    routes {
      id
      ...RouteDetails
      ...RouteAbilities
    }
    ...BranchesDetails
    ...PlanAbilities
  }
  ${PLAN_BRANCHES}
  ${PLAN_ABILITIES}
  ${ROUTE_DETAILS}
  ${ROUTE_ABILITIES}
`
export const CLIENT_DETAILS = gql`
  fragment ClientDetails on Client {
    fullName
    name
    settings
    contact {
      name
      email
    }
  }
`

