import {gql, useLazyQuery} from "@apollo/client";
import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";
import Spinner from "../../components/Spinner";
import Breadcrumbs from "../../components/Breadcrumbs";
import {Button} from "../../components/catalyst/button";
import {Link, useNavigate} from "react-router-dom"
import {buildDate, formatDateRange} from "../../helpers";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import PlanCreateWizard from "./create/PlanCreateWizard";
import ErrorPage from "../../components/ErrorPage";
import * as Sentry from "@sentry/react";
import {CLIENT_DETAILS, USER_ABILITIES} from "../../fragments";
import Alert from "../../components/Alert";
import {eachDayOfInterval} from "date-fns";
import PlanCard from "./PlanCard";

const QUERY = gql`
  query getPlans {
    me {
      id
      permanentBase {
        id
        uuid
        metadata
      }
      plans {
        id
        dateRange {
          start
          finish
        }
        boundingBox {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
      clientAssignments {
        active
        client {
          id
          ...ClientDetails
        }
      }
      ...UserAbilities
    }
  }
  ${CLIENT_DETAILS}
  ${USER_ABILITIES}
`

const plannedStyle = {border: '2px solid #d0d0d0', background: '#f0f0f0'};


const PlanCreateAlert = ({ability}) => {
  if (ability.value) return null

  const defaultMessage = ability.reasons.fullMessages

  const details = JSON.parse(ability.reasons.details)
  const details_keys = _.get(details, 'user', [])
  const detail_messages = details_keys.map(key => {
    switch (key) {
      case "missing_base":
        return {
          text: "Please set your permanent base location in the settings page, to start planning.",
          link: "/settings"
        };
      case "missing_branches":
        return {
          text: "Please import branches to start planning.",
          link: "/import"
        }
      case "demo":
        return {
          text: "Can't create plans in the demo.",
          link: null
        }
      default:
        return {
          text: "Not authorized to create plans.",
          link: null
        }
    }
  })

  if (_.isEmpty(detail_messages)) {
    return <Alert message={defaultMessage} target={null}/>
  } else {
    return (
      <>
        {detail_messages.map((m, index) => <Alert message={m.text} target={m.link} key={index}/>)}
      </>
    )
  }
}

const PlanVisitsBar = ({plan}) => {
  const visits = plan.routes.visits ?? []

  const color = {
    "PLANNED": "bg-yellow-200 border-yellow-700",
    "CONFIRMED": "bg-blue-200 border-blue-700",
    "COMPLETED": "bg-green-200 border-green-700",
    "CANCELLED": "bg-red-200 border-red-700",
    "AUTHORIZED": "bg-purple-200 border-purple-700"
  }

  return (
    <div className="flex flex-row gap-x-1">
      {visits.map(visit => (
        <div key={visit.id} className={`size-3 border ${color[visit.state.toUpperCase()]} rounded`}/>
      ))}
    </div>
  )
}

const Plans = ({}) => {
  const [plans, setPlans] = useState([])
  const [clientAssignments, setClientAssignments] = useState([])
  const [base, setBase] = useState(null)
  const [user, setUser] = useState(null)
  let navigate = useNavigate();

  const [createOpen, setCreateOpen] = useState(false)

  const [fetchPlans, {loading, error}] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setPlans([])
      } else {
        const {me} = data
        setUser(me)
        setPlans(me.plans)
        setClientAssignments(me.clientAssignments)
        setBase(me.permanentBase.metadata)
      }
    }
  })

  useEffect(() => {
    fetchPlans()
  }, []);

  const onPlanCreated = (id) => {
    setCreateOpen(false)
    navigate(`/plans/${id}`)
  }

  const sortedPlans = useMemo(() => {
    return [...plans].sort((a, b) => buildDate(b.dateRange.start) - buildDate(a.dateRange.start));
  }, [plans])

  const takenDays = useMemo(() => {
    const intervals = plans.map(p => eachDayOfInterval({
      start: buildDate(p.dateRange.start),
      end: buildDate(p.dateRange.finish)
    }))
    const flat = intervals.flat()
    return [...new Set(flat)]
  }, [plans])

  if (loading || error || _.isNil(user)) {
    if (error) {
      Sentry.captureException(error)
      return <ErrorPage/>
    } else {
      return <Spinner/>
    }
  }

  const pages = [
    {name: "Plans", href: `#`},
  ]

  const canCreatePlan = user.canCreatePlan.value

  return (
    <div className="min-h-full  flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      <PlanCreateAlert ability={user.canCreatePlan}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Plans
          </div>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0 gap-x-2">
          <Button color="green" onClick={() => setCreateOpen(true)}>
            Create Plan
          </Button>
        </div>
      </div>

      {/*<div className="flex flex-row mt-4 gap-x-3 items-start">*/}
      {/*  <ul role="list" className="divide-y divide-gray-100 bg-white px-2 border rounded flex-1 ">*/}
      {/*    {sortedPlans.map((plan) => (*/}
      {/*      <li key={plan.id} className="relative flex justify-between gap-x-6 py-5 hover:bg-slate-50">*/}
      {/*        <div className="flex min-w-0 gap-x-4">*/}
      {/*          /!*<img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt=""/>*!/*/}
      {/*          <div className="min-w-0 flex-auto">*/}
      {/*            <p className="text-sm font-semibold leading-6 text-gray-900">*/}
      {/*              <Link to={`/plans/${plan.id}`}>*/}
      {/*                <span className="absolute inset-x-0 -top-px bottom-0"/>*/}
      {/*                {formatDateRange(plan.dateRange, {*/}
      {/*                  year: 'numeric', month: 'long', day: 'numeric', hour: undefined, minute: undefined*/}
      {/*                })}*/}
      {/*              </Link>*/}
      {/*            </p>*/}
      {/*            <div className="mt-1 flex text-xs leading-5 text-gray-500">*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="flex shrink-0 items-center gap-x-4">*/}
      {/*          <div className="hidden sm:flex sm:flex-col sm:items-end">*/}
      {/*            /!*<p className="text-sm leading-6 text-gray-900">{assignment.client.name}</p>*!/*/}
      {/*            /!*<Status active={assignment.active}/>*!/*/}
      {/*            /!*{person.lastSeen ? (*!/*/}
      {/*            /!*  <p className="mt-1 text-xs leading-5 text-gray-500">*!/*/}
      {/*            /!*    Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>*!/*/}
      {/*            /!*  </p>*!/*/}
      {/*            /!*) : (*!/*/}
      {/*            /!*  <div className="mt-1 flex items-center gap-x-1.5">*!/*/}
      {/*            /!*    <div className="flex-none rounded-full bg-emerald-500/20 p-1">*!/*/}
      {/*            /!*      <div className="h-1.5 w-1.5 rounded-full bg-emerald-500"/>*!/*/}
      {/*            /!*    </div>*!/*/}
      {/*            /!*    <p className="text-xs leading-5 text-gray-500">Online</p>*!/*/}
      {/*            /!*  </div>*!/*/}
      {/*            /!*)}*!/*/}
      {/*          </div>*/}
      {/*          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>*/}
      {/*        </div>*/}
      {/*      </li>*/}
      {/*    ))}*/}
      {/*  </ul>*/}
      {/*</div>*/}

      <div className="flex flex-row mt-4 gap-x-3 items-start">
        {sortedPlans.map((plan) => (
          <PlanCard plan={plan} key={plan.id} />
        ))}
      </div>



      <PlanCreateWizard userPlace={base}
                        open={createOpen}
                        onClose={() => setCreateOpen(false)}
                        onPlanCreated={onPlanCreated}
                        disabledDays={takenDays}
                        canCreate={canCreatePlan}
      />
    </div>

  )
}

export default Plans