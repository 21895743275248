import {Description, Field, Label} from "../../components/catalyst/fieldset";
import {Input} from "../../components/catalyst/input";
import {CopyIcon} from "lucide-react";
import {Button} from "../../components/catalyst/button";
import CalendarInstructions from "./CalendarInstructions";


const CalendarSetting = ({url}) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      alert('Calendar URL copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  return (
    <Field className="w-full">
      <div className="flex flex-row gap-x-4 items-center">
        <Button onClick={handleCopy} color="white">
          <CopyIcon className="size-4"/>
        </Button>
        <Input value={url} disabled className="max-w-full"/>
      </div>
      <Description>
        Use this URL to subscribe to your personal RouteSched calendar.
        Don't share it.
      </Description>
      <CalendarInstructions/>
    </Field>
  )
}

export default CalendarSetting