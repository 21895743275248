import BaseCard from "../../../components/BaseCard";
import {GoogleMap, InfoWindow, Marker, Polyline, useLoadScript} from "@react-google-maps/api";
import React, {useState} from "react";
import chroma from "chroma-js"
import {BranchInfoWindow} from "./BranchInfoWindow";
import _ from "lodash";

const BranchesMap = ({branches, base}) => { // TODO: add bbox from backend
  const [selectedBranch, setSelectedBranch] = useState(null)

  const mapContainerStyle = {
    width: '100%',
    height: '65lvh',
  };
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    libraries: ['places'],
  });

  const onMarkerClick = (branch) => {
    setSelectedBranch(branch)
  }

  // FIXME: do this on re-renders
  const onMapLoad = (map) => {
    if (_.isEmpty(branches)) {
      const center = new window.google.maps.LatLng(base?.lat || 0, base?.lng || 0);
      const zoom = _.isNil(base) ? 2 : 12
      map.setCenter(center);
      map.setZoom(zoom)
    } else {
      const bounds = new window.google.maps.LatLngBounds();
      branches.forEach(branch => {
        bounds.extend(branch.place.metadata.geometry.viewport.northeast)
        bounds.extend(branch.place.metadata.geometry.viewport.southwest)
      })
      map.fitBounds(bounds)
    }
  }

  if (loadError) return <div>Error loading maps</div>
  if (!isLoaded) return <div>Loading maps</div>

  const branchIcon = (branch) => {
    let branchColor = branch.client.settings.main_color

    return ({
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: branchColor,
      fillOpacity: 1,
      scale: 8,
      strokeColor: chroma(branchColor).darken(2).hex(),
      strokeWeight: 3,
    })
  }

  return (
    <div className="flex-1 max-w-full min-w-full">
      <BaseCard title={`Places: ${branches.length}`}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >

          {branches.map((branch) => {
            return (
              <Marker
                key={branch.id}
                position={branch.place.metadata.geometry.location}
                icon={branchIcon(branch)}
                onClick={() => onMarkerClick(branch)}
              >
                {selectedBranch?.id === branch.id && (
                  <InfoWindow onCloseClick={() => setSelectedBranch(null)}>
                    <BranchInfoWindow branch={branch}/>
                  </InfoWindow>
                )
                }
              </Marker>
            )
          })}

        </GoogleMap>

      </BaseCard>
    </div>
  )

}

export default BranchesMap