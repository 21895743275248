import React from 'react'
import {GoogleLogin} from "@react-oauth/google";
import {useAuth} from "../auth/useAuth";
import {ReactComponent as Logo} from '../components/route-sched-logo.svg'

export default function Login() {
  const {signIn} = useAuth()

  const handleCredentialResponse = (response) => {
    signIn(response.credential)
    window.location.replace("/")
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="size-32 mx-auto" title="RouteSched">
            <Logo/>
          </div>
          <h2 className="mt-6 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">
            Welcome to RouteSched
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">

            <div className="mt-6 flex items-center justify-center">
              <GoogleLogin
                onSuccess={handleCredentialResponse}
                onError={() => {
                  // Sentry.captureMessage("failed to log in")
                }}
              />
            </div>
          </div>

          {/*<p className="mt-10 text-center text-sm text-gray-500">*/}
          {/*  Not a member?{' '}*/}
          {/*  <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">*/}
          {/*    Start a 14 day free trial*/}
          {/*  </a>*/}
          {/*</p>*/}
        </div>
      </div>
    </>
  )
}
