import {gql, useLazyQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import _ from "lodash";
import Spinner from "../../../components/Spinner";
import {GoogleMap, Marker, Polyline, useLoadScript} from '@react-google-maps/api';
import home from "../../../data/home.json"
import {calculateViewportCenter, categoryColors, sumViewports} from "../../../helpers";
import * as Sentry from "@sentry/react";


const QUERY = gql`
  query getClient($id: ID!) {
    node(id: $id) {
      ... on Client {
        branches {
          id
          metadata
          place {
            metadata
            id
            uuid
          }
        }
      }
    }
  }
`


const Map = ({}) => {
  const {id} = useParams()

  const [fetchClientBranches, {loading, error, data}] = useLazyQuery(QUERY, {
    variables: {id: id},
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    fetchClientBranches()
  }, []);

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    libraries: ['places'],
  });

  if (loading || error || _.isUndefined(data)) {
    if (error) {
      Sentry.captureException(error)
      return <ErrorPage/>
    } else {
      return <Spinner/>
    }
  }

  const {node} = data
  const {branches} = node
  const locations = branches.map(b => b.place.metadata)
  const homeLocation = home.geometry.location

  const totalViewport = sumViewports([...locations, home])
  const center = calculateViewportCenter(totalViewport)
  const mapContainerStyle = {
    width: '100%',
    height: '1000px',
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={6}
    >
      <Marker position={homeLocation}/>

      {locations.map((location, i) => {
        const geo = location.geometry.location
        // const cluster = paths.find(c => c.waypoints.includes(location.place_id))
        // const color = categoryColors[cluster.id]

        // const icon = {
        //   // Google Charts provides a simple way to create colored markers
        //   // Replace 'color' in the URL with your desired marker color
        //   url: `http://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|${color}`,
        //   // Specify the desired icon size (optional)
        //   // scaledSize: new window.google.maps.Size(40, 40),
        // };

        return (
          <Marker
            key={i}
            // icon={icon}
            position={geo}
          />
        )
      })}

    </GoogleMap>

  )

}

export default Map