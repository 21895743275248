import {EllipsisVerticalIcon} from '@heroicons/react/20/solid'
import {
  Dropdown,
  DropdownButton, DropdownHeader, DropdownHeading,
  DropdownItem,
  DropdownLabel,
  DropdownMenu, DropdownSection, DropdownSeparator
} from "../../../components/catalyst/dropdown";
import {CheckIcon} from "@heroicons/react/24/solid";
import _ from "lodash";
import {gql, useMutation} from "@apollo/client";
import * as Sentry from '@sentry/react';
import {enqueueSnackbar} from "notistack";
import {PLAN_DETAILS} from "../../../fragments";
import {VISIT_STATES} from "../../../helpers";

const SET_VISIT_STATE = gql`
  mutation setVisitState($visitId:ID!, $state: VisitState!) {
    routeSetVisitState(input: {visitId: $visitId, state: $state}){
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`

const SET_VISIT_TIMESPAN = gql`
  mutation setVisitTimeSpan($visitId:ID!, $span: TimeSpan!) {
    routeSetVisitTimespan(input: {visitId: $visitId, timeSpan: $span}){
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`


const VisitStateDropdown = ({visit, onPlanChange}) => {

  const SPANS = ["TS_15", "TS_30", "TS_45", "TS_60"]

  const [setVisitState] = useMutation(SET_VISIT_STATE)
  const [setVisitTimeSpan] = useMutation(SET_VISIT_TIMESPAN)

  const onStateChange = (newState) => {
    if (_.isFunction(onPlanChange)) {
      setVisitState({variables: {visitId: visit.id, state: newState.toUpperCase()}})
      .then(response => {
        onPlanChange(response.data.routeSetVisitState)
      })
      .catch(error => {
        enqueueSnackbar(`Could not change the state of the visit: ${error.message}`, {variant: "error"})
        Sentry.captureException(error)
      })
    }
  }

  const onSpanChange = (newSpan) => {
    if (_.isFunction(onPlanChange)) {
      setVisitTimeSpan({variables: {visitId: visit.id, span: newSpan}})
      .then(response => {
        onPlanChange(response.data.routeSetVisitTimespan)
      })
      .catch(error => {
        enqueueSnackbar(`Could not change the duration of the visit: ${error.message}`, {variant: "error"})
        Sentry.captureException(error)
      })
    }
  }

  return (
    <Dropdown >
      <DropdownButton plain aria-label="More options">
        <EllipsisVerticalIcon/>
      </DropdownButton>
      <DropdownMenu className="text-right z-50">
        {/*<DropdownHeader>*/}
        {/*  <div className="pr-6">*/}
        {/*    <div className="text-xs text-zinc-500 dark:text-zinc-400">Signed in as Tom Cook</div>*/}
        {/*  </div>*/}
        {/*</DropdownHeader>*/}
        <DropdownSection>
          <DropdownHeading>Visit State</DropdownHeading>
          {VISIT_STATES.map((state, i) => (
            <DropdownItem onClick={() => onStateChange(state)} value={state} key={i}>
              {state === visit.state && <CheckIcon/>}
              <DropdownLabel className="capitalize">{state.toLowerCase()}</DropdownLabel>
            </DropdownItem>
          ))}
          <div/>
        </DropdownSection>
        <DropdownSeparator />
        <DropdownSection>
          <DropdownHeading>Visit Length</DropdownHeading>
          {SPANS.map((span, i) => (
            <DropdownItem onClick={() => onSpanChange(span)} value={span} key={i}>
              {span === visit.timeSpan && <CheckIcon/>}
              <DropdownLabel>{(i+1) * 15}m</DropdownLabel>
            </DropdownItem>
          ))}
        </DropdownSection>
      </DropdownMenu>
    </Dropdown>
  )
}

export default VisitStateDropdown