import BaseCard from "../../../components/BaseCard";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {shortDate} from "../../../helpers";
import {ImageIcon, NotepadText} from "lucide-react";
import VisitBadge from "../../plans/view/VisitBadge";
import React from "react";
import {Link} from "react-router-dom";


const VisitsCard = ({branch}) => {

  const {visits} = branch


  return (
    <BaseCard title="Visits">
      <ul role="list" className="divide-y divide-gray-100 p-4">
        {visits.map((visit) => {
            const plan = visit.route.plan

            return (
              <li key={visit.id} className="relative flex justify-between gap-x-6 gap-y-4">
                <div className="flex min-w-0 gap-x-4">
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      <Link to={`/visits/${visit.id}`}>
                        <span className="absolute inset-x-0 -top-px bottom-0"/>
                        {shortDate(plan.date)}
                      </Link>
                    </p>
                    <div className="mt-1 flex">
                      <ImageIcon className="size-5"/>
                      <NotepadText className="size-5"/>
                    </div>
                  </div>
                </div>
                <div className="flex shrink-0 items-center gap-x-4">
                  <div className="hidden sm:flex sm:flex-col sm:items-end">
                    <VisitBadge visit={visit}/>
                    <p className="mt-1 text-xs leading-5 text-gray-500">
                      {/*Last seen <time dateTime={"poop"}>{"poop"}</time>*/}
                    </p>
                  </div>
                  <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                </div>
              </li>
            )
          }
        )}
      </ul>
    </BaseCard>
  )
}

export default VisitsCard