import {Link} from "react-router-dom";
import * as Sentry from "@sentry/react";


const ErrorPage = ({}) => {

  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        {/*<p className="text-base font-semibold text-indigo-600">Error</p>*/}
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Error</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn't make it work.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back home
          </Link>
          <a href="mailto:support@arsawatt.com" className="text-sm font-semibold text-gray-900" target="_blank">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  )
}

export default ErrorPage