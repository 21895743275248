import React from "react";
import _ from "lodash";
import {addMinutes, addSeconds, intervalToDuration, startOfDay} from "date-fns";
import {Ending} from "./logistics/Ending";
import {Leg} from "./logistics/Leg";
import {Starting} from "./logistics/Starting";
import {Visit} from "./logistics/Visit";
import VisitsStateDropdown from "./logistics/VisitsStateDropdown";
import {buildDate} from "../../../helpers";


const bufferMinutes = 10

const Itinerary = ({user, plan,  route, onPlanChange, onRecenter}) => {
  if (_.isNil(route)) return null

  const directions = route.directions[0]
  const orderedVisits = directions.waypoint_order.map(index => route.visits[index]).filter(x => !_.isNil(x))

  const totalTime = _.sumBy(directions.legs, leg => leg.duration.value)
  const totalDistance = _.sumBy(directions.legs, leg => leg.distance.value)

  const duration = intervalToDuration({start: 0, end: totalTime * 1000});

  let date = buildDate(route.date)
  let time = startOfDay(date)
  const startTime = addMinutes(time, route.startOffset)

  time = startTime
  let times = [startTime]

  time = addSeconds(time, directions.legs[0].duration.value) // time after first leg
  time = addMinutes(time, bufferMinutes)

  orderedVisits.forEach((visit, index, array) => {
    times.push(time) // visit time

    // time travelled
    time = addMinutes(time, parseInt(visit.timeSpan.split("_")[1]))
    time = addSeconds(time, directions.legs[index + 1].duration.value)
    time = addMinutes(time, bufferMinutes)
  })
  times.push(time)

  return (
    <div className="flow-root px-0 py-3 border-2 rounded-xl">
      <div className="mb-3 px-2">
        <VisitsStateDropdown route={route} onChange={onPlanChange}/>
      </div>

      <ul role="list" className="-mb-8">
        <li key="starting">
          <div className="relative pb-4">
            <Starting
              key={route.id}
              route={route}
              time={times[0]}
              onPlanChange={onPlanChange}
              onRecenter={onRecenter}
            />
          </div>
        </li>

        {orderedVisits.map((visit, i) => {
          return (
            <div key={i}>
              <li key={`${visit.id}-leg`}>
                <div className="relative pb-4">
                  <Leg leg={directions.legs[i]}/>
                </div>
              </li>
              <li key={visit.id}>
                <div className="relative pb-4">
                  <Visit visit={visit}
                         plan={plan}
                         index={i}
                         user={user}
                         onPlanChange={onPlanChange}
                         time={times[i + 1]}
                         onRecenter={onRecenter}
                  />
                </div>
              </li>
            </div>
          )
        })}

        <li key="ending_leg">
          <div className="relative pb-4">
            <Leg leg={directions.legs[directions.legs.length - 1]}/>
          </div>
        </li>
        <li key="ending">
          <div className="relative pb-16">
            <Ending key={route.id}
                    route={route}
                    time={times[times.length - 1]}
                    onPlanChange={onPlanChange}
                    onRecenter={onRecenter}
            />
          </div>
        </li>
      </ul>

      <div className="text-xs text-slate-400 flex items-center gap-x-5 px-2">
        {directions.copyrights}

        {/* FIXME: is this even necessary ? */}
        {/*<div className="flex items-center gap-x-2">*/}
        {/*  <CarIcon className="size-6"/>*/}
        {/*  <div className="flex items-center gap-x-1">*/}
        {/*    <RulerIcon className="size-4"/>*/}
        {/*    {_.round(totalDistance / 1000, 2)} Km*/}
        {/*  </div>*/}
        {/*  <div className="flex  items-center gap-x-1">*/}
        {/*    <TimerIcon className="size-4"/>*/}
        {/*    {humanizeDuration(duration, {delimiter: ', '})}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default Itinerary