import {DayPicker} from "react-day-picker";
import React, {useState} from "react";
import {addMonths, isSameMonth} from "date-fns";
import {Button} from "../../../components/catalyst/button";
import {Field, Label} from "../../../components/catalyst/fieldset";

const PickDate = ({planStartDate, setPlanStartDate, disabledDays}) => {
  const today = new Date()
  const thisMonth = addMonths(new Date(), 0);
  const [month, setMonth] = useState(thisMonth);

  const footer = (
    <div className="flex-row gap-x-1 flex">
      <Button disabled={isSameMonth(today, month)} onClick={() => setMonth(today)} outline>
        Today
      </Button>
    </div>
  );

  return (
    <Field>
      <Label>Starting Date</Label>

      <DayPicker
        className="bg-slate-50 rounded rounded-s"
        style={{margin: 0}}
        mode="single"
        month={month}
        onMonthChange={setMonth}
        footer={footer}
        today={today}
        fixedWeeks
        // modifiers={{planned: plannedDays}}
        // modifiersStyles={{planned: plannedStyle}}
        selected={planStartDate}
        onSelect={setPlanStartDate}
        disabled={disabledDays}

      />
    </Field>
  )

}

export default PickDate