import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/20/solid'
import {buildDate, classNames, formatDate, getUsersDailyHours, shortDate} from "../../helpers";
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format, isAfter, isSameDay,
  isSameMonth,
  isToday, isWithinInterval,
  startOfMonth,
  startOfWeek
} from "date-fns";
import _ from "lodash";
import React from "react";
import {Link} from "react-router-dom";
import {Button} from "../../components/catalyst/button";
import {useAuth} from "../../auth/useAuth";


const isWorkingDate = (user, date) => {
  if (_.isUndefined(user)) return true

  const hours = getUsersDailyHours(user, date)
  if (_.isNil(hours)) return false
  return hours.active
}

function getMonthData(today, date, user) {
  const monthStart = startOfMonth(date);
  const monthEnd = endOfMonth(date);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const days = eachDayOfInterval({start: startDate, end: endDate}).map(day => ({
    date: format(day, 'yyyy-MM-dd'),
    isCurrentMonth: isSameMonth(day, monthStart),
    isWorkingDay: isWorkingDate(user, day),
    isToday: isSameDay(day, today),
  }));

  return {
    name: format(monthStart, 'MMMM yyyy'),
    days: days,
  };
}

function generateMonthsData(startDate, user, numberOfMonths = 1) {
  const months = [];
  for (let i = 0; i < numberOfMonths; i++) {
    const monthStartDate = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1);
    const monthData = getMonthData(startDate, monthStartDate, user);
    months.push(monthData);
  }
  return months;
}

const PlanLink = ({plan, children}) => {
  if (_.isUndefined(plan) || _.isNil(plan)) {
    return children
  } else {
    return (
      <Link to={`/plans/${plan.id}`}>
        {children}
      </Link>
    )
  }
}

const routeDate = (route) => formatDate(route.date, {
  year: undefined,
  month: 'short',
  day: '2-digit',
  weekday: "short",
  hour: undefined,
  minute: undefined
})

export default function Agenda({user}) {
  const {isDemo} = useAuth()

  const plans = user?.plans || []

  const today = isDemo ? new Date(2024, 3, 9) : new Date()

  const events =
    _(plans)
    .flatMap("routes")
    .filter(r => {
      const routeDate = buildDate(r.date)
      return isSameDay(routeDate, today) || isAfter(routeDate, today)
    })
    .sortBy(r => buildDate(r.date))
    .take(5)
    .value()

  const months = generateMonthsData(today, user, 2)


  return (
    <div>
      <div className="relative grid grid-cols-1 gap-x-14 md:grid-cols-2">
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="absolute -left-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"*/}
        {/*>*/}
        {/*  <span className="sr-only">Previous month</span>*/}
        {/*  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>*/}
        {/*</button>*/}
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="absolute -right-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"*/}
        {/*>*/}
        {/*  <span className="sr-only">Next month</span>*/}
        {/*  <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>*/}
        {/*</button>*/}
        {months.map((month, monthIdx) => (
          <section
            key={monthIdx}
            className={classNames(monthIdx === months.length - 1 && 'hidden md:block', 'text-center')}
          >
            <h2 className="text-sm font-semibold text-gray-900">{month.name}</h2>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
              <div>S</div>
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
            </div>
            <div
              className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
              {month.days.map((day, dayIdx) => {
                const date = buildDate(day.date)
                const dayPlan = plans.find(plan => {
                  const interval = {start: buildDate(plan.dateRange.start), end: buildDate(plan.dateRange.finish)}
                  return isWithinInterval(date, interval)
                })

                return (
                  <button
                    key={day.date}
                    type="button"
                    className={classNames(
                      (day.isCurrentMonth && isWorkingDate(user, date)) ? 'bg-white text-gray-900' : 'bg-gray-50 text-gray-400',
                      dayIdx === 0 && 'rounded-tl-lg',
                      dayIdx === 6 && 'rounded-tr-lg',
                      dayIdx === month.days.length - 7 && 'rounded-bl-lg',
                      dayIdx === month.days.length - 1 && 'rounded-br-lg',
                      'relative py-1.5 hover:bg-gray-100 focus:z-10'
                    )}
                  >
                    <PlanLink plan={dayPlan}>
                      <time
                        dateTime={day.date}
                        className={classNames(
                          day.isToday && 'bg-indigo-600 font-semibold text-white',
                          'mx-auto flex h-7 w-7 items-center justify-center rounded-full'
                        )}
                      >
                        {day.date.split('-').pop().replace(/^0/, '')}
                      </time>
                      <span className="size-3">
                        <div className={classNames(dayPlan ? "bg-indigo-500 " : "", "size-3 mx-auto mt-1")}/>
                      </span>
                    </PlanLink>
                  </button>
                )
              })}
            </div>
          </section>
        ))}
      </div>

      <section className="mt-12">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Upcoming Visits
        </h2>
        {_.isEmpty(events) &&
          <div className="mt-2 text-sm">
            <p className="py-2">No upcoming visits scheduled.</p>
            <Link to="/plans">
              <Button color="green">Create Plans</Button>
            </Link>
          </div>
        }

        <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
          {events.map(event => {
            return (
              <li className="py-2 sm:flex" key={event.date}>
                <time dateTime="2022-01-19" className="w-28 flex-none underline">
                  <PlanLink plan={event.plan}>
                    {routeDate(event)}
                  </PlanLink>
                </time>
                <div className="mt-2 flex-auto text-gray-900 sm:mt-0">
                  <ol className="divide-y divide-slate-50 text-xs">
                    {event.visits.map(v => (
                      <li className="flex gap-x-2">
                        <div className="underline">
                          <Link to={`/visits/${v.id}`}>
                            {v.branch.name}
                          </Link>
                        </div>
                        <div className="underline">
                          <Link to={`/places/${v.branch.id}`}>
                            {v.branch.place.metadata.formatted_address}
                          </Link>
                        </div>
                      </li>
                    ))}
                  </ol>

                </div>
                {/*<p className="flex-none sm:ml-6">*/}
                {/*  <time dateTime="2022-01-13T14:30">2:30 PM</time>*/}
                {/*  - <time dateTime="2022-01-13T16:30">4:30 PM</time>*/}
                {/*</p>*/}
              </li>
            )
          })}

        </ol>
      </section>
    </div>
  )
}
