import React from 'react';
import {Listbox, ListboxLabel, ListboxOption} from "./catalyst/listbox";
import {gql, useMutation} from "@apollo/client";
import {PLAN_DETAILS} from "../fragments";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";

const SET_START_OFFSET = gql`
  mutation setRouteStartOffset($id:ID!, $offset:Int!) {
    routeSetStartOffset(input: {routeId: $id, offset: $offset}){
      id
      ...PlanDetails
    }
  }
  ${PLAN_DETAILS}
`

function createTimeOptions(minutesIncrement = 30) {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += minutesIncrement) {
      const value = hour * 60 + minute;
      const label = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      options.push({value, label});
    }
  }
  return options;
}

const TimeOffsetSelect = ({route, onChange, className}) => {
  const timeOptions = createTimeOptions();
  const [setStartOffset] = useMutation(SET_START_OFFSET)

  const onOffsetChange = (offset) => {
    setStartOffset({variables: {id: route.id, offset: offset}})
    .then(response => {
      const thePlan = response.data.routeSetStartOffset
      onChange(thePlan)
    })
    .catch(error => {
      enqueueSnackbar("Could not set the starting offset of the route.", {variant: "error"})
      Sentry.captureException(error)
    })
  }


  return (
    <div className={className}>
      <Listbox value={route.startOffset} onChange={onOffsetChange}>
        {timeOptions.map((option) => (
          <ListboxOption key={option.value} value={option.value}>
            <ListboxLabel>{option.label}</ListboxLabel>
          </ListboxOption>
        ))}
      </Listbox>
    </div>
  )
}

export default TimeOffsetSelect;
