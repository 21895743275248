import {Trash2Icon} from "lucide-react";
import {Button} from "../../../components/catalyst/button";
import React, {useState} from "react";
import {Alert, AlertActions, AlertDescription, AlertTitle} from "../../../components/catalyst/alert";
import {gql, useMutation} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";

const DELETE_PLAN = gql`
  mutation deletePlan($planId: ID!) {
    planDelete(input: {planId: $planId})
  }
`

const DeleteAlert = ({plan, isOpen, onClose, onConfirm}) => {
  return (
    <Alert open={isOpen} onClose={onClose}>
      <AlertTitle>Are you sure you want to delete the plan?</AlertTitle>
      <AlertDescription>
        Deleting a plan will also delete all its recorded visits.
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>Cancel</Button>
        <Button color="red" onClick={onConfirm}>Delete</Button>
      </AlertActions>
    </Alert>
  )
}


const Delete = ({plan, onDelete, className}) => {
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [deletePlan] = useMutation(DELETE_PLAN)

  const handleDeleteConfirm = () => {
    setDeleteAlert(false)
    deletePlan({variables: {planId: plan.id}})
    .then(response => {
      enqueueSnackbar("Plan deleted.", {variant: "success"})
      onDelete()
    })
    .catch(error => {
      enqueueSnackbar(`Could not delete the plan: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  return (
    <>
      <button onClick={() => setDeleteAlert(true)} color="red" className={className}>
        <Trash2Icon className="size-5 mr-1"/>
        Delete
      </button>
      <DeleteAlert plan={plan} isOpen={deleteAlert} onClose={() => setDeleteAlert(false)}
                   onConfirm={handleDeleteConfirm}/>
    </>
  )
}

export default Delete