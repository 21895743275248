import {useParams} from "react-router-dom";
import locations from "../data/optometrists.json"
import paths from "../data/paths.json"
import home from "../data/home.json"

import {
  calculateViewportCenter,
  categoryColors,
  classNames,
  clusterName,
  decodePolyline,
  sumViewports
} from "../helpers";
import {GoogleMap, Marker, Polyline, useLoadScript} from '@react-google-maps/api';


const CarRoute = ({}) => {
  const {id} = useParams()
  const cluster = paths[id]

  const clusterLocations = cluster.waypoints.map(point => locations.find(l => l.place_id === point))

  const totalViewport = sumViewports([...clusterLocations, home])
  const center = calculateViewportCenter(totalViewport)
  const mapContainerStyle = {
    width: '100%',
    height: '1000px',
  };

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    libraries: ['places'],
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const decodedPath = decodePolyline(cluster.directions[0].overview_polyline.points);

  return (
    <>
      <h2>Route {clusterName(cluster)}</h2>


      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={14}
      >
        {/*<Marker position={vancouver}/>*/}

        {clusterLocations.map((location, i) => {
          const geo = location.geometry.location

          return (
            <Marker
              key={i}
              position={geo}
            />
          )
        })}

        <Polyline
          path={decodedPath}
          options={{
            strokeColor: '#0000FF',
            strokeOpacity: 0.75,
            strokeWeight: 5,
            fillColor: '#0000FF',
            fillOpacity: 0.35,
          }}
        />

      </GoogleMap>
    </>
  )
}

export default CarRoute