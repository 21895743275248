import {ArrowRightFromLineIcon, LocateFixed} from "lucide-react";
import React, {useEffect, useRef} from "react";
import Autocomplete from "react-google-autocomplete";
import {gql, useMutation} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import TimeOffsetSelect from "../../../../components/TimeOffsetSelect";
import _ from "lodash";

const SET_STARTING = gql`
  mutation setRouteStarting($id:ID!, $uuid:String!) {
    routeSetStarting(input: {routeId: $id, placeUuid: $uuid}) {
      id
    }
  }
`

const api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

export const Starting = ({route, onPlanChange, onRecenter}) => {
  const [setStarting] = useMutation(SET_STARTING)
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.value = route.starting.metadata.formatted_address;
    }
  }, [route]);

  const onPlaceChange = (place) => {
    if (_.isUndefined(place.place_id)) {
      enqueueSnackbar(`Failed to geocode the address '${place.name}'. Please try simplifying it and confirm it exists.`, {variant: "error"})
      Sentry.captureMessage("Address could not be geocoded", {extra: place})
      return
    }

    setStarting({variables: {id: route.id, uuid: place.place_id}})
    .then(response => {
      onPlanChange()
    })
    .catch(error => {
      console.error(error)
      enqueueSnackbar("Could not set the starting of the route.", {variant: "error"})
      Sentry.captureException(error)
    })
  }

  return (
    <div className="relative flex items-center gap-x-3 pr-1">
      {/*<span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"/>*/}
      <div className="relative px-1">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-1 ring-white">
          <ArrowRightFromLineIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-0">
        <div className="text-xs text-gray-500">
          <div className="font-medium text-gray-900">
            <div className="flex flex-row gap-x-4 items-center">
              <TimeOffsetSelect route={route} onChange={onPlanChange} className="min-w-2"/>

              <div className="flex-1">
                <Autocomplete
                  ref={ref}
                  apiKey={api_key}
                  onPlaceSelected={onPlaceChange}
                  options={{types: ['geocode', "establishment"], componentRestrictions: {country: "ca"}}}
                  defaultValue={route.starting.metadata.formatted_address}
                  className=" w-full rounded px-2 py-2 border"
                  placeholder="starting address..."
                />
              </div>
              {/*<div className="flex gap-x-0.5 items-center">*/}
              {/*  <Button onClick={() => onRecenter(route.starting.metadata.geometry)} color="white" disabled={!_.isFunction(onRecenter)}>*/}
              {/*    <LocateFixed className="size-3"/>*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}