
const VisitBadge = ({visit}) => {
  const state = visit.state.toUpperCase()

  const COLORS = {
    "PLANNED": "bg-yellow-50 text-yellow-800 ring-yellow-600/20",
    "CONFIRMED": "bg-blue-50 text-blue-700 ring-blue-700/10",
    "COMPLETED": "bg-green-50 text-green-700 ring-green-700/10",
    "CANCELLED": "bg-red-50 text-red-700 ring-red-700/10",
    "AUTHORIZED": "bg-purple-50 text-purple-700 ring-purple-700/10"
  }

  const color = COLORS[state] + " inline-flex items-center rounded-md font-medium ring-1 ring-inset px-1 py-0.5 uppercase text-xs"
  return (
    <span className={color}>
      {state.toLowerCase()}
    </span>
  )
}

export default VisitBadge