import React, {useEffect, useMemo, useState} from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import {Button} from "../../components/catalyst/button";
import _ from "lodash";
import {transformer} from "./transformer";
import {gql, useMutation, useQuery} from "@apollo/client";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import {useNavigate} from "react-router-dom";
import * as XLSX from "xlsx";
import {USER_ABILITIES} from "../../fragments";
import Preview from "./Preview";
import Guide from "./Guide";

const QUERY = gql`
  query getUser {
    me {
      ...UserAbilities
    }
  }
  ${USER_ABILITIES}
`

const UPLOAD_BRANCHES = gql`
  mutation uploadBranches($filename: String!,  $branches:[BranchImportInput!]!) {
    importBranches(input: {filename: $filename, branches: $branches}) {
      id
    }
  }
`

export const MEANINGS = [
  "ignore",
  "address",
  "name",
  "client"
]

const Import = () => {
  const [header, setHeader] = useState([])
  const [rows, setRows] = useState([])
  const [mapping, setMapping] = useState([])
  const [filename, setFilename] = useState("")

  const [uploadBranches] = useMutation(UPLOAD_BRANCHES)
  let navigate = useNavigate();

  const {loading, error, data} = useQuery(QUERY, {fetchPolicy: "network-only"})

  const pages = [
    {name: "Import", href: `#`},
  ]

  useEffect(() => {
    setMapping(new Array(header.length).fill(MEANINGS[0]))
  }, [header])

  const canImport = useMemo(() => {
    return _.some(mapping, x => x === "address") &&
      _.some(mapping, x => x === "name") &&
      _.some(mapping, x => x === "client") &&
      (!_.isNil(data) && data.me.canAddBranch.value)
  }, [mapping, data])

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, {type: 'binary'});
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      setFilename(file.name)
      setRows(jsonData.map(row => Object.values(row)));
      setHeader(Object.keys(jsonData[0]))
    };

    reader.readAsBinaryString(file);
  }

  const onImport = () => {
    const transformed = transformer(rows, mapping)
    uploadBranches({variables: {filename: filename, branches: transformed}})
    .then(response => {
      enqueueSnackbar("Import started. It might take a few minutes until the data is fully processed.", {variant: "success"})
      navigate("/files");
    })
    .catch(error => {
      enqueueSnackbar(`Could not import the branches: ${error.message}`, {variant: "error"})
      Sentry.captureException(error)
    })
  }

  return (
    <div className="min-h-full  flex flex-col gap-y-4">
      <Breadcrumbs pages={pages}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Import
          </div>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0 gap-x-2">
          <Button color="green" onClick={onImport} disabled={!canImport}>Import</Button>
        </div>
      </div>

      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={onFileSelect}
        className="border border-gray-300 rounded-lg p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white min-w-96"
      />

      <Preview header={header} rows={rows} mapping={mapping} setMapping={setMapping}/>

      <Guide mapping={mapping} rows={rows}/>
    </div>
  )


}

export default Import