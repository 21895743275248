import {Route, Routes} from "react-router-dom";
import React from "react";
import CarRoutes from "./pages/CarRoutes"
import CarRoute from './pages/CarRoute'
import Clients from "./pages/clients"
import {useAuth} from "./auth/useAuth";
import Logout from "./login/Logout";
import Login from "./login";
import ClientView from "./pages/clients/view";
import {default as ClientBranches} from "./pages/clients/view/Branches";
import {default as ClientMap} from "./pages/clients/view/Map"
import Branches from "./pages/branches";
import {default as BranchView} from "./pages/branches/view"
import Layout from "./components/Layout";
import Settings from "./pages/settings";
import Plans from "./pages/plans";
import PlanView from "./pages/plans/view";
import VisitView from "./pages/plans/visits";
import Import from "./pages/import";
import Files from "./pages/files";
import Dashboard from "./pages/dashboard";
import ReactPixel from "react-facebook-pixel";
import Hotjar from "@hotjar/browser";

const Root = () => {
  const {isAuthenticated, isDemo} = useAuth()

  const hotjarSiteId = isDemo ? 5007186 : 5007330;
  const hotjarVersion = 6;

  Hotjar.init(hotjarSiteId, hotjarVersion);

  ReactPixel.pageView();

  if (!isAuthenticated) {
    ReactPixel.track("ViewContent", { content_name: "Login Page" })
    return (
      <Routes>
        <Route path="/" element={<Login/>}/>
      </Routes>
    )
  } else {

    if (isDemo) {
      ReactPixel.track("ViewContent", { content_name: "Demo" })
    } else {
      ReactPixel.track("ViewContent", { content_name: "Application" })
      ReactPixel.track("StartTrial") // FIXME: skip for known users
    }

    return (
      <Routes>
        <Route element={<Layout/>}>
          <Route path="/logout" element={<Logout/>}/>

          <Route path="/" element={<Dashboard/> } />

          <Route path="/clients" element={<Clients/>}/>
          <Route path="/clients/:id" element={<ClientView/>}>
            <Route path="branches?" element={<ClientBranches/>}/>
            <Route path="map" element={<ClientMap/>} />
          </Route>

          <Route path="/places" element={<Branches/>}/>
          <Route path="/places/:id" element={<BranchView/>}/>

          <Route path="/import" element={<Import/>} />

          <Route path="/files" element={<Files/>} />


          <Route path="/plans" element={<Plans/>} />
          <Route path="/plans/:id" element={<PlanView/>}/>
          <Route path="/visits/:id" element={<VisitView/>}/>

          <Route path="/routes" element={ <CarRoutes/> }>
            <Route path=":id" element={<CarRoute/>} />
          </Route>

          <Route path="/settings" element={<Settings/> } />

          <Route
            path="*"
            element={<main style={{padding: "1rem"}}><p>There's nothing here!</p></main>}
          />
        </Route>
      </Routes>
    )
  }
}

export default Root
