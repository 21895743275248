import React, {useEffect, useState} from "react";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import _ from "lodash";
import {useParams} from "react-router-dom";
import Spinner from "../../../components/Spinner";
import Breadcrumbs from "../../../components/Breadcrumbs";
import WeeklySchedule from "../../../components/WeeklySchedule";
import StaticMapCard from "../../../components/StaticMapCard";
import PhoneButton from "../../../components/PhoneButton";
import WebButton from "../../../components/WebButton";
import BranchInfoCard from "../../../components/BranchInfoCard";
import VisitsCard from "./VisitsCard";
import {enqueueSnackbar} from "notistack";
import * as Sentry from "@sentry/react";
import ErrorPage from "../../../components/ErrorPage";

const QUERY = gql`
  query getBranch($id: ID!) {
    node(id: $id) {
      ... on Branch {
        id
        name
        client {
          name
        }
        place {
          id
          dailyHours {
            active
            open
            close
            weekday
          }
          metadata
        }
        visits {
          id
          state
          route {
            date
            plan {
              id
            }
          }
        }
      }
    }
  }
`

const SET_DAILY_HOUR = gql`
  mutation setDailyHour($placeId:ID!, $weekday:Weekday!, $active:Boolean, $open: Int, $close: Int) {
    placeSetWeekday(input: {placeId: $placeId, weekday: $weekday, active: $active, openMinutes: $open, closeMinutes: $close}) {
      active
      open
      close
      weekday
    }
  }
`

const BranchView = () => {
  const {id} = useParams()
  const [branch, setBranch] = useState({})
  const [setDailyHour] = useMutation(SET_DAILY_HOUR)
  const [fetchBranch, {loading, error, data}] = useLazyQuery(QUERY, {
    variables: {id: id},
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setBranch({})
      } else {
        setBranch(data.node)
      }
    }
  })

  useEffect(() => {
    fetchBranch()
  }, []);

  if (loading || error || _.isEmpty(branch)) {
    if (error) {
      Sentry.captureException(error)
      return <ErrorPage/>
    } else {
      return <Spinner/>
    }
  }

  const onWeekdayChange = (value) => {
    const variables = _.assign({placeId: branch.place.id}, value)
    setDailyHour({variables: variables})
    .then(response => {
      const newDailyHours = response.data.placeSetWeekday
      setBranch((prevBranch) => {
        let newBranch = structuredClone(prevBranch);
        newBranch.place.dailyHours = newDailyHours
        return newBranch
      })
    })
    .catch(error => {
      enqueueSnackbar("Could not set the daily hours for the schedule.", {variant: "error"})
      Sentry.captureException(error)
    })
  }

  const pages = [
    {name: "Branches", href: `/branches`},
    {name: branch.name, href: `#`}
  ]

  return (
    <div className="min-h-full flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {branch.name}
          </div>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0 gap-x-2">
          <PhoneButton number={branch.place.metadata.formatted_phone_number}/>
          <WebButton url={branch.place.metadata.website}/>
        </div>
      </div>

      <div className="flex flex-row flex-wrap mt-6 gap-4">
        <BranchInfoCard branch={branch}/>
        <WeeklySchedule schedule={branch.place.dailyHours} onChange={onWeekdayChange}/>
        <StaticMapCard place={branch.place}/>
        <VisitsCard branch={branch}/>
      </div>
    </div>
  )


}

export default BranchView