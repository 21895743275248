import BaseCard from "./BaseCard";
import {useState} from "react";
import {Button} from "./catalyst/button";

const FAKE_TEXT = "Today's visit to the branch was nothing short of fantastic! From the moment I stepped in, the energy and enthusiasm from the team were palpable, making it clear we were all on the same wavelength about promoting our latest glasses range. The staff's genuine interest in understanding the features and benefits of our products was inspiring, ensuring they're well-equipped to pass on this knowledge to their customers. Their feedback was incredibly positive, highlighting how our glasses not only meet but exceed customer expectations with their style, comfort, and functionality. This visit has further solidified our partnership, setting the stage for what I'm confident will be a highly successful promotion in their optics store. It's days like these that truly underscore the value of our work and the impact we can have, and I'm already looking forward to our next collaboration!"

const NotesCard = ({notes, onChange}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(notes)

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onChange(text);
  };

  const handleKeyDown = (event) => {
    // if (event.key === 'Enter') {
    //   event.target.blur(); // Triggers handleBlur by removing focus
    // }
  };

  return (
    <BaseCard title="Notes" >
      <div onClick={() => setIsEditing(true)} className=" w-full p-2">
        {isEditing ? (
          <>
            <textarea
              rows={10}
              value={text}
              onChange={handleTextChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              className="min-w-72 p-1 w-full prose"
            />
            <Button color="green" onClick={() => handleBlur()}>Save</Button>
          </>
        ) : (
          <div className="prose prose-slate p-1 min-w-96 h-52 overflow-y-scroll">
            {text}
          </div>
        )
        }
      </div>
    </BaseCard>
  )
}

export default NotesCard
