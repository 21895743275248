import {BuildingOfficeIcon, CreditCardIcon, UserIcon, UsersIcon} from '@heroicons/react/20/solid'
import {classNames} from "../../helpers";
import {useState} from "react";
import BaseCard from "../../components/BaseCard";
import {Text, Code} from "../../components/catalyst/text";


const tabs = [
  {name: 'MacOS Calendar', icon: UserIcon},
  {name: 'Google Calendar', icon: BuildingOfficeIcon},
]


const Tabs = ({selected, onChange}) => {

  return (
    <div>
      <div className="block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <div
                key={tab.name}
                onClick={() => onChange(index)}
                className={classNames(
                  (selected === index)
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-4 px-3 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <span>{tab.name}</span>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}


const MacInstructions = () => (
  <div>
    <ol className="list-decimal">
      <li><Text>Copy the Calendar URL. Either click on the copy button above, or select the text and copy it
        manually.</Text></li>
      <li><Text>Open the MacOS Calendar application.</Text></li>
      <li><Text>In the <Code>File</Code> menu, select <Code>New Calendar Subscription...</Code></Text></li>
      <li><Text>In the input box paste the URL from step 1. Click <Code>Subscribe</Code></Text></li>
      <li>
        <Text>
          Once the Calendar connects to the server, fill in the name (e.g. <b>RouteSched</b>) and other details of
          the calendar subscription. Set the <Code>Auto-Refresh</Code> frequency to your preference ("Every day"
          recommended).
          Click <Code>OK</Code>.
        </Text>
      </li>

    </ol>
  </div>
)

const GoogleInstructions = () => (
  <div>
    <ol className="list-decimal">
      <li><Text>Copy the Calendar URL. Either click on the copy button above, or select the text and copy it
        manually.</Text></li>
      <li><Text>Open the Google Calendar application.</Text></li>
      <li><Text>On the left side bar, next to <Code>Other Calendars</Code> click the <Code>+</Code> button.
        Select <Code>From URL</Code></Text></li>

      <li><Text>In the input box paste the URL from step 1. Click <Code>Add calendar</Code></Text></li>

    </ol>
  </div>
)


const CalendarInstructions = () => {
  const [selected, setSelected] = useState(0)

  return (
    <div className="mt-2">
      <BaseCard title="">
        <Tabs selected={selected} onChange={setSelected}/>
        <div className="m-4 px-4">
          {selected === 0 && <MacInstructions/>}
          {selected === 1 && <GoogleInstructions/>}
        </div>

      </BaseCard>
    </div>
  )
}

export default CalendarInstructions;