import {Link, useLocation} from "react-router-dom";
import _ from "lodash"
import {classNames} from "../helpers";

const Tabs = ({tabs}) => {
  const location = useLocation()

  const isCurrentTab = (tab) => {
    const name = tab.name.toLowerCase()
    const segments = location.pathname.split('/');
    const lastSegment = _.last(segments).toLowerCase()
    return _.isEqual(lastSegment, name)
  }

  const handleTabChange = (event, newValue) => {
    console.debug(event)
  }

  return (
    <div className="">
      <div className="sm:block">
        <div className="border-b border-gray-300">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => {
              const current = isCurrentTab(tab)
              return (
                <Link key={tab.name} to={tab.href}
                      className={classNames(
                        current
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium'
                      )}
                >
                  {tab.name}
                </Link>
              )
            })}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Tabs
