import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {useLoadScript} from "@react-google-maps/api";
import PlanMap from "./PlanMap";
import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import {innerButtonStyle, leftButtonStyle, rightButtonStyle, shortDateRange} from "../../../helpers";
import * as Sentry from "@sentry/react";
import ErrorPage from "../../../components/ErrorPage";
import Spinner from "../../../components/Spinner";
import {PLAN_ABILITIES, PLAN_DETAILS, USER_DETAILS} from "../../../fragments";
import Drawer from "./Drawer";
import {Button} from "../../../components/catalyst/button";
import {MapIcon} from "@heroicons/react/24/outline";
import {default as DeletePlan} from "../../plans/buttons/Delete"
import {default as UpdatePlan} from "../../plans/buttons/Update"

const mapLibraries = ['places', 'drawing', 'geometry']

const PLAN_QUERY = gql`
  query getPlan($id: ID!) {
    node(id: $id) {
      ... on Plan {
        id
        ...PlanDetails
        ...PlanAbilities
      }
    }
    me {
      ...UserDetails
    }
  }
  ${PLAN_DETAILS}
  ${PLAN_ABILITIES}
  ${USER_DETAILS}
`


const PlanView = () => {
  const {id} = useParams()
  const [plan, setPlan] = useState(null)
  const [selectedRoute, setSelectedRoute] = useState(null)
  const [drawerOpen, drawerSetOpen] = useState(false)
  const [mapStatus, setMapStatus] = useState("")
  const [userSettings, setUserSettings] = useState(null)
  let navigate = useNavigate();

  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    libraries: mapLibraries,
  });


  const [fetchPlan, {loading, error, data}] = useLazyQuery(PLAN_QUERY, {
    variables: {id: id},
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setPlan({})
        setSelectedRoute(null)
        setUserSettings(null)
      } else {
        const plan = data.node
        setPlan(plan)
        setUserSettings(data.me)
      }
    }
  })
  useEffect(() => {
    fetchPlan()
  }, []);

  useEffect(() => {
    setSelectedRoute(plan?.routes[0])
  }, [plan])

  console.log(`maps loaded ${isLoaded}`)
  if (loadError) return <div>Error loading maps</div>
  if (!isLoaded) return <div>Loading maps</div>

  if (error) {
    Sentry.captureException(error)
    return <ErrorPage/>
  }
  if (loading || _.isNil(plan)) return <Spinner/>

  const pages = [
    {name: "Plans", href: `/plans`},
    {name: shortDateRange(plan.dateRange), href: `#`},
  ]

  const onPlanDeleted = () => {
    navigate("/plans");
  }

  const onPlanUpdated = (plan) => {
    setPlan(plan)
  }

  return (
    <div className="min-h-full flex flex-col gap-y-4">
      <Drawer
        user={userSettings}
        plan={plan}
        setSelectedRoute={setSelectedRoute}
        selectedRoute={selectedRoute}
        onPlanChange={setPlan}
        open={drawerOpen} setOpen={drawerSetOpen}
      />

      <div className="flex justify-between">
        <Breadcrumbs pages={pages}/>
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button onClick={() => drawerSetOpen(true)} className={leftButtonStyle}>
            <MapIcon className="size-6 mr-1"/>
            Itinerary
          </button>
          <UpdatePlan plan={plan} onUpdate={setPlan} className={innerButtonStyle}/>
          <DeletePlan plan={plan} onDelete={onPlanDeleted} className={rightButtonStyle}/>
        </span>

      </div>


      <div className="flex flex-row flex-wrap gap-4">
        <PlanMap plan={plan}
                 selectedRoute={selectedRoute}
                 setSelectedRoute={setSelectedRoute}
                 onPlanChange={onPlanUpdated}
                 onViewportChange={setMapStatus}
        />
      </div>

    </div>
  )
}

export default PlanView