import {ArrowDownIcon, ArrowUpIcon} from '@heroicons/react/20/solid'
import {classNames} from "../../helpers";
import {CarIcon, ListChecksIcon, StoreIcon, UsersIcon} from "lucide-react";
import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {UserGroupIcon} from "@heroicons/react/24/outline";


const STATS_QUERY = gql`
  query getStats {
    me {
      stats
    }
  }
`


export default function Stats() {
  const [stats, setStats] = useState(
    [
      {
        name: 'Places Known',
        icon: StoreIcon,
        label: "View all places",
        href: "/places",
        stat: null,
        change: null,
        changeType: null
      },
      {
        name: 'Routes Planned',
        icon: CarIcon,
        label: "View Plans",
        href: "/plans",
        stat: null, change: null, changeType: null
      },
      {
        name: 'Clients',
        icon: UserGroupIcon,
        label: "View Clients",
        href: "/clients",
        stat: null, change: null, changeType: null
      },
    ]
  )


  const [fetchStats, {loading, error, data}] = useLazyQuery(STATS_QUERY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setStats([])
      } else {
        const {planned_routes, branches_known, clients} = data.me.stats
        setStats(prevStats => {
          const newStats = _.cloneDeep(prevStats)
          newStats[0] = {...prevStats[0], stat: branches_known.count}
          newStats[1] = {...prevStats[1], stat: planned_routes.count}
          newStats[2] = {...prevStats[2], stat: clients.count}
          return newStats
        })
      }
    }
  })
  useEffect(() => {
    fetchStats()
  }, [])

  const validStats = useMemo(() => {
    return _(stats).filter(s => !_.isNull(s.stat)).value()
  }, [stats])


  return (
    <div>
      {/*<h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>*/}

      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {validStats.map((item, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-indigo-500 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true"/>
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>

              {item.change &&
                <p
                  className={classNames(
                    item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                    'ml-2 flex items-baseline text-sm font-semibold'
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true"/>
                  ) : (
                    <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true"/>
                  )}

                  <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                  {item.change}
                </p>
              }
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                <div className="text-sm capitalize">
                  <Link to={item.href} className="font-medium text-indigo-600 hover:text-indigo-500">
                    {item.label}
                  </Link>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}



