import _ from "lodash";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "../../components/catalyst/table";
import React from "react";
import {Listbox, ListboxLabel, ListboxOption} from "../../components/catalyst/listbox";
import {MEANINGS} from "./index";


const HUMANIZED = {
  "ignore": "ignore",
  "client": "Client Name",
  "address": "Address",
  "name": "Branch Name"

}

const MeaningDropdown = ({value, onChange}) => {
  return (
    <Listbox name="status" value={value} onChange={onChange}>
      {
        MEANINGS.map(option => (
          <ListboxOption value={option} key={option}>
            <ListboxLabel className="capitalize">{HUMANIZED[option]}</ListboxLabel>
          </ListboxOption>
        ))
      }
    </Listbox>
  )
}


const ColumnMatcher = ({title, value, onChange}) => {
  return (
    <div className="flex flex-col gap-y-2">
      {title}
      <MeaningDropdown value={value} onChange={onChange}/>
    </div>
  )
}

const Preview = ({rows, header, mapping, setMapping}) => {

  const onMeaningChange = (index, value) => {
    const dup = [...mapping]
    dup[index] = value
    setMapping(dup)
  }

  if (_.isEmpty(rows) || _.isEmpty(header) || _.isEmpty(mapping)) {
    return null
  }

  return (
      <Table striped grid dense className="max-h-64 overflow-y-scroll bg-white border border-gray-300 rounded-lg ">
        <TableHead>
          <TableRow>
            {header.map((headerTitle, index) => {
              const meaning = mapping[index]
              return (
                <TableHeader key={index}>
                  <ColumnMatcher title={headerTitle}
                                 value={meaning}
                                 onChange={(value) => onMeaningChange(index, value)}
                  />
                </TableHeader>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i}>
              {row.map((cell, i) => (<TableCell key={i}>{cell}</TableCell>))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
  )
}



export default Preview