import React from "react";


const BaseCard = ({title, children, actions = null}) => {
  return (
    <div className="max-w-full">
      <div className="overflow-hidden bg-white sm:rounded-lg sm:shadow">
        {title &&
          <div className="border-b border-gray-200 bg-white px-2 py-3 sm:px-3 flex items-center">
            <h3 className="text-base font-semibold leading-6 text-gray-900 flex-1">{title}</h3>
            {actions}
          </div>
        }
        {children}
      </div>
    </div>
  )
}

export default BaseCard