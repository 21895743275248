import {gql, useQuery} from "@apollo/client";
import Breadcrumbs from "../../components/Breadcrumbs";
import React from "react";
import _ from "lodash";
import Stats from "./Stats";
import Agenda from "./Agenda";
import BaseCard from "../../components/BaseCard";
import {USER_DETAILS} from "../../fragments";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";


const QUERY = gql`
  query Dashboard {
    me {
      clients {
        id
      }
      stats
      ...UserDetails
      plans {
        id
        dateRange {
          start
          finish
        }
        routes {
          plan {
            id
          }
          date
          visits {
            id
            timeSpan
            branch {
              id
              name
              place {
                metadata
              }
            }
          }
        }
      }
    }
  }
  ${USER_DETAILS}
`
const pages = [
  {name: "Dashboard", href: `#`},
]

const Dashboard = ({}) => {

  const {loading, error, data} = useQuery(QUERY, {fetchPolicy: "network-only"})

  if (loading || error || _.isUndefined(data)) {
    return <Spinner/>
  }

  const branchesCount = data.me.stats.branches_known.count
  const noBranches = branchesCount === 0
  const noPermBase = _.isEmpty(data.me.permanentBase)

  return (
    <div className="min-h-full flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      {(noPermBase || noBranches) &&
        <div>
          {noPermBase &&
            <Alert message="Please set your permanent base location in the settings page." target="/settings"/>}
          {noBranches && <Alert message="No Branches found. Import branches to start planning." target="/import"/>}
        </div>
      }

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Dashboard
          </div>
        </div>
      </div>

      <Stats/>

      <BaseCard title="Agenda">
        <div className="p-4">
          <Agenda user={data?.me}/>
        </div>
      </BaseCard>

    </div>
  )
}

export default Dashboard