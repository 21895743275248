import {StaticGoogleMap, Marker} from "react-static-google-map";
import BaseCard from "./BaseCard";

const StaticMapCard = ({place}) => {
  const mapApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const geometry = place.metadata.geometry
  const address = place.metadata.formatted_address
  const url = place.metadata.url ?? `https://www.google.com/maps/place/${encodeURIComponent(address)}/@${geometry.location.lat},${geometry.location.lng}`

  return (
    <div className="max-w-none ">
      <BaseCard title="Location">
        <a href={url} target="_blank">
          <StaticGoogleMap size="400x400"
                           className="img-fluid"
                           apiKey={mapApiKey}
                           center={geometry.location}>
            <Marker location={geometry.location}/>
          </StaticGoogleMap>
        </a>
      </BaseCard>

      {/*<div className="overflow-hidden bg-white sm:rounded-lg sm:shadow">*/}
      {/*  <div className="border-b border-gray-200 bg-white px-2 py-3 sm:px-3">*/}
      {/*    <h3 className="text-base font-semibold leading-6 text-gray-900">Location</h3>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default StaticMapCard