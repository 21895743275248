import {Link} from "react-router-dom";
import React from "react";
import {shortDate} from "../../../helpers";


const BranchLink = ({branch}) => (
  <Link to={`/places/${branch.id}`} className="underline cursor-pointer">
    <div className="font-bold">{branch.name}</div>
    <div className="font-extralight text-slate-500">{branch.place.metadata.formatted_address}</div>
  </Link>
)

export const BranchInfoWindow = ({branch}) => {
  // const plan = branch.lastVisit.route.plan
  return (
    <div className="text-xs text-slate-700">
      <BranchLink branch={branch}/>
      {/*<div className="mt-2">Plan: {shortDate(plan.date)}</div>*/}
    </div>
  )
}






