import {Table, TableBody, TableCell, TableRow} from "./catalyst/table";
import React from "react";
import BaseCard from "./BaseCard";


const BaseInfoCard = ({title, tableData, className = ""}) => {
  return (
    <BaseCard title={title}>
      <Table striped dense className={className}>
        <TableBody>
          {Object.entries(tableData).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell className="font-bold text-left capitalize">{key}</TableCell>
              <TableCell className="">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </BaseCard>
  )
}

export default BaseInfoCard