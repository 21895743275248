import React, {useEffect, useState} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import Spinner from "../../../components/Spinner";
import * as Sentry from "@sentry/react";
import BranchesMap from "./BranchesMap";
import ErrorPage from "../../../components/ErrorPage";
import MapLegend from "../../plans/view/MapLegend";


// TODO: change the backend to search without a page
const QUERY = gql`
  query getClients($query: String!) {
    me {
      permanentBase {
        point {
          lng
          lat
        }
      }
    }
    searchBranches(query: $query, page: {offset: 0, limit: 1000000}) {
      total
      branches {
        id
        name
        client {
          settings
        }
        place {
          metadata
          name
          point {
            lat
            lng
          }
        }
      }
    }
  }
`


const BranchesMapPanel = ({query}) => {
  const [mapData, setMapData] = useState([])
  const [base, setBase] = useState(null)

  const [fetchClients, {loading, error, data}] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
    variables: {query: query},
    onCompleted: (data) => {
      if (_.isUndefined(data)) {
        setMapData([])
      } else {
        const {branches} = data.searchBranches
        setMapData(branches)
        setBase(data.me.permanentBase.point)
      }
    }
  })

  useEffect(() => {
    fetchClients()
  }, [query]);

  if (loading || error || _.isUndefined(data)) {
    if (error) {
      Sentry.captureException(error)
      return <ErrorPage/>
    } else {
      return <Spinner/>
    }
  }

  return (
    <div className="flex flex-row flex-wrap gap-4">
      <BranchesMap branches={mapData} base={base}/>
    </div>
  )

}

export default BranchesMapPanel
