import React, {useEffect} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import _ from "lodash";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Spinner from "../../../components/Spinner";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import Tabs from "../../../components/Tabs";
import ErrorPage from "../../../components/ErrorPage";


const QUERY = gql`
  query getClient($id: ID!) {
    node(id: $id) {
      ... on Client {
        id
        fullName
        name
        settings
        contact {
          name
          email
        }
      }
    }
  }
`


const ClientView = () => {
  const {id} = useParams()
  const navigate = useNavigate()

  const [fetchClient, {loading, error, data}] = useLazyQuery(QUERY, {
    variables: {id: id},
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    fetchClient()
  }, []);

  if (loading || error || _.isUndefined(data)) {
    if (error) {
      Sentry.captureException(error)
      return <ErrorPage/>
    } else {
      return <Spinner/>
    }
  }

  const {node} = data

  const pages = [
    {name: "Clients", href: `/clients`},
    {name: node.name, href: `#`},
  ]

  const tabs = [
    {name: 'Branches', href: `branches`},
    {name: 'Map', href: `map`},
  ]

  return (
    <div className="min-h-full flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="h-12 w-2 mr-2" style={{backgroundColor: node.settings.main_color}}/>
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {node.fullName}
          </div>
        </div>
        {!_.isNil(node.contact) &&
          <div className="mt-4 flex md:ml-4 md:mt-0 gap-x-2 items-baseline text-sm  ">

            <div className="capitalize">{node.contact?.name}</div>
            <div className="font-mono">{`<${node.contact?.email}>`}</div>
          </div>
        }
      </div>


      <Tabs tabs={tabs}/>

      <div className="">
        <Outlet/>
      </div>


    </div>
  )


}

export default ClientView