import React from "react";

export const Status = ({metadata}) => {

  const statusColor = (business_status) => {
    switch (business_status) {
      case "OPERATIONAL":
        return "bg-emerald-500"
      case "CLOSED_TEMPORARILY":
        return "bg-amber-500"
      case "CLOSED_PERMANENTLY":
        return "bg-zinc-500"
      default:
        console.log(business_status)
    }
  }

  const coronaColor =`${statusColor(metadata.business_status)}/20`
  const innerColor = statusColor(metadata.business_status)

  return (
    <div className="mt-1 flex items-center gap-x-1.5" title={metadata.business_status}>
      <div className={`flex-none rounded-full ${coronaColor} p-1`}>
        <div className={`h-1.5 w-1.5 rounded-full ${innerColor}`}/>
      </div>
    </div>
  )
}