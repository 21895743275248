import Autocomplete from "react-google-autocomplete";
import React from "react";

const api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const AddressSelector = ({onPlaceSelected, defaultValue, placeholder, disabled}) => {

  return (
    <Autocomplete
      apiKey={api_key}
      onPlaceSelected={onPlaceSelected}
      options={{
        types: ['geocode', "establishment"],
        componentRestrictions: {country: "ca"},
      }}
      defaultValue={defaultValue}
      className="w-full rounded px-2 py-2 border"
      placeholder={placeholder}
      disabled={disabled}
      data-1p-ignore
    />
  )
}

export default AddressSelector