import React, {Fragment, useEffect, useState} from "react";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import _ from "lodash";
import Spinner from "../../components/Spinner";
import {Link} from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import {EllipsisVerticalIcon} from '@heroicons/react/20/solid'
import * as Sentry from "@sentry/react";
import ErrorPage from "../../components/ErrorPage";
import NoBranches from "../../components/NoBranches";
import {Menu, Transition} from "@headlessui/react";
import {classNames} from "../../helpers";
import {enqueueSnackbar} from "notistack";
import {Alert, AlertActions, AlertDescription, AlertTitle} from "../../components/catalyst/alert";
import {Button} from "../../components/catalyst/button";

const QUERY = gql`
  query getClients {
    me {
      id
      clientAssignments {
        active
        client {
          name
          fullName
          id
          contact {
            name
            email
          }
          settings
          branches {
            id
          }
        }
      }
      canAddBranch {
        value
      }
    }
  }
`

const DELETE_CLIENT = gql`
  mutation deleteClient($id: ID!) {
    clientDelete(input: {clientId: $id})
  }
`

const Status = ({active}) => {
  const coronaColor = active ? "bg-emerald-500/20" : "bg-zinc-500/20"
  const innerColor = active ? "bg-emerald-500" : "bg-zinc-500"
  const word = active ? "Active" : "Offline"

  return (
    <div className="mt-1 flex items-center gap-x-1.5">
      <div className={`flex-none rounded-full ${coronaColor} p-1`}>
        <div className={`h-1.5 w-1.5 rounded-full ${innerColor}`}/>
      </div>
      <p className="text-xs leading-5 text-gray-500">{word}</p>
    </div>
  )
}

const Clients = () => {
  const [deleteClient] = useMutation(DELETE_CLIENT)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)

  const [fetchClients, {loading, error, data}] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    fetchClients()
  }, []);

  if (loading || error || _.isUndefined(data)) {
    if (error) {
      Sentry.captureException(error)
      return <ErrorPage/>
    } else {
      return <Spinner/>
    }
  }

  const DeleteAlert = ({isOpen, onClose, onConfirm}) => {
    return (
      <Alert open={isOpen} onClose={onClose}>
        <AlertTitle>Are you sure you want to delete {selectedClient?.name}?</AlertTitle>
        <AlertDescription>
          Deleting a client will also delete all its branches and recorded visits.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={onClose}>Cancel</Button>
          <Button color="red" onClick={onConfirm}>Delete</Button>
        </AlertActions>
      </Alert>
    )
  }


  const onClientDelete = (client => {
    setSelectedClient(client)
    setDeleteAlert(true)
  })

  const handleDeleteConfirm = () => {
    setDeleteAlert(false);

    deleteClient({variables: {id: selectedClient.id}})
    .then(response => {
      enqueueSnackbar("Client deleted.", {variant: "success"})
      fetchClients()
    })
    .catch(error => {
      enqueueSnackbar("Could not delete the client.", {variant: "error"})
      Sentry.captureException(error)
    })
    .finally(() => {
      setSelectedClient(null);
    })
  }

  const {me} = data
  const {clientAssignments: assignments} = me

  const pages = [
    {name: "Clients", href: `#`},
  ]

  const canAddBranch = me.canAddBranch.value
  const showEmpty = !loading && !error && !_.isNil(data) && _.isEmpty(data.me.clientAssignments)

  return (
    <div className="min-h-full flex flex-col gap-y-4">

      <Breadcrumbs pages={pages}/>

      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 flex-row">
          <div className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Clients
          </div>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0 gap-x-2">
          {/*<Button onClick={() => setBranchDialog(true)} color="green" disabled={!canAddBranch}>*/}
          {/*  <PlusIcon className="size-4 stroke-white fill-white"/>*/}
          {/*  Add Branch*/}
          {/*</Button>*/}
        </div>
      </div>

      {showEmpty && <NoBranches/>}

      <ul role="list" className="divide-y divide-gray-100 bg-white px-2 border rounded mt-4">
        {assignments.map((assignment) => {
          const bg_color = assignment.client.settings.main_color
          return (
            <li key={assignment.client.id} className="relative flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <div className={`h-12 w-2`} style={{backgroundColor: bg_color}}/>
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <Link to={`/clients/${assignment.client.id}`}>
                      <span className="absolute inset-x-0 -top-px bottom-0"/>
                      {assignment.client.name}
                    </Link>
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    {/*<a href={`mailto:${person.email}`} className="relative truncate hover:underline">*/}
                    {assignment.client.fullName}
                    {/*</a>*/}
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-4">
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900"><b>{assignment.client.branches.length}</b> branches</p>
                  <Status active={assignment.active}/>
                </div>

                <Menu as="div" className="relative flex-none">
                  <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true"/>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({active}) => (
                          <div className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                          )} onClick={() => onClientDelete(assignment.client)}
                          >
                            Delete
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </li>
          )
        })}
      </ul>

      <DeleteAlert isOpen={deleteAlert} onClose={() => setDeleteAlert(false)} onConfirm={handleDeleteConfirm}/>
    </div>
  )


}

export default Clients